.cut__card {
   height: auto;
   width: 60rem;
   border-radius: 11px;
   border-top-left-radius: 0;
   background-color: #fff;
   position: relative;
   width: 100%;
   box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4)
}

.cut-download-container {
   height: 550px;
   width: 100%;
   border-radius: 11px;
   border-top-left-radius: 0;
   background-color: #fff;
   display: flex;
   gap: 1rem;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   overflow-x: hidden;
   overflow-y: auto;
   box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4)
}

.cut-download-container2 {
   height: auto;
   padding: 1rem;
   width: 70%;
   border-radius: 11px;
   text-align: center;
   background-color: #eeeeee;
   display: flex;
   padding-inline: 2rem;
   margin-bottom: 1rem;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   overflow-x: hidden;
   overflow-y: auto;
   box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4);
  
}

.cut-download-text {
   font-size: 1.6rem;
   color: black;
}

.cut-download-description {
   font-size: 1rem;
   color: rgb(53, 53, 53);
   font-family: "readex";
   text-justify: auto;
   
}

.cut-download-container .react-datepicker__input-container input {
   padding: 10px;
   border: 2px solid #ccc;
   border-radius: 5px;
   font-size: 16px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   transition: border-color 0.3s;
}

.cut-download-container .react-datepicker__input-container input:focus {
   border-color: #bda000;
   outline: none;
}

.cut-download-container .react-datepicker__input-container input:hover {
   border-color: #ffd700;
}

/* Estilo del contenedor del calendario */
.react-datepicker__portal {
   position: fixed;
   z-index: 9999;
   background-color: rgba(0, 0, 0, 0.3);
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
}

/* Estilo del mes y año */
.react-datepicker__current-month {
   font-size: 1.2rem;
   font-weight: bold;
   padding: 10px;
   background-color: #fff;
   color: #000;
   border-bottom: 1px solid #ccc;
}

/* Estilo de la lista de meses */
.react-datepicker__month-container {
   width: 100%;
}

/* Estilo de cada mes en la lista */
.react-datepicker__month-text {
   cursor: pointer;
   padding: 10px;
   border-bottom: 1px solid #ccc;
   background-color: #fff;
   color: #000;
   text-align: center;
}

/* Estilo del mes seleccionado */
.react-datepicker__month-text--selected {
   background-color: #000;
   color: #fff;
}

/* Estilo de hover sobre los meses */
.react-datepicker__month-text:hover {
   background-color: #f0f0f0;
}

/* Estilo de los días */
.react-datepicker__day-names,
.react-datepicker__week {
   display: none;
   /* Ocultar nombres de los días y semanas */
}

/* Estilo de los botones de navegación de fecha */
.react-datepicker__navigation {
   top: 10px;
   line-height: 1.7;
   border: none;
   background: none;
   cursor: pointer;
   padding: 10px;
   font-size: 1.5rem;
   color: #000;
}

/* Estilo de los botones de navegación de fecha al pasar el mouse */
.react-datepicker__navigation:hover {
   color: #333;
}

/* Estilo del día seleccionado */
.react-datepicker__day--selected {
   background-color: #000;
   color: #fff;
}

/* Estilo del día actual */
.react-datepicker__day--today {
   font-weight: bold;
}

/* Estilo de los días fuera de mes */
.react-datepicker__day--outside-month {
   color: #999;
}

/* Estilo de los días con eventos */
.react-datepicker__day--has-events {
   background-color: #ffcccc;
}

/* Estilo del botón de cerrar */
.react-datepicker__close-icon {
   cursor: pointer;
   background: none;
   border: none;
   padding: 5px;
   position: absolute;
   top: 10px;
   right: 10px;
   font-size: 1.5rem;
   color: #000;
}

/* Estilo del botón de cerrar al pasar el mouse */
.react-datepicker__close-icon:hover {
   color: #333;
}

.cut-download-button {
   display: flex;
   align-items: center;
   gap: 4px;
   background-color: black;
   color: white;
   font-family: 'bebas';
   border: none;
   padding: 8px 16px;
   font-size: 1.4rem;
   cursor: pointer;
   border-radius: 4px;
   transition: background-color 0.3s ease;

   &:hover {
      opacity: 90%;
      transform: translateY(-2px);
   }
}

.cut__card-history {
   height: 550px;
   width: 100%;
   border-radius: 11px;
   border-top-left-radius: 0;
   background-color: #fff;
   position: relative;
   overflow-x: hidden;
   overflow-y: auto;
   box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4)
}

.cut__header {
   color: rgb(255, 255, 255);
   font-size: 1.3rem;
   font-weight: 300;
   border-radius: 11px 11px 0px 0px;
   border-top-left-radius: 0;
   position: sticky;
   top: 0;
   padding-inline: 1rem;
   position: sticky;
   height: 60px;
   width: 100%;
   background-color: #000;
   display: flex;
   justify-content: space-around;
   align-items: center;
   
}

.card__body {
   display: flex;
   /* flex-flow: column; */
   flex-direction: row;
   justify-content: space-evenly;
   
   width: 100%;
   height: 400px;
   
}

@media screen and (max-width: 900px) {
   .card__body{
      flex-direction: column;
      overflow-y: auto;
      
  }
  
}

.cut__header__title {
   display: flex;
   flex-direction: row;
   justify-content: left;
   gap: 10px;
   flex: 1
}
.cut__header__title2 {
   display: flex;
   flex-direction: row;
   justify-content: center;
   gap: 10px;
   flex: 1
}

.cut__row {
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* Alinea verticalmente los elementos */
}

.cut__row2 {
   margin-top: 1rem;
   width: 100%;
   display: flex;
   justify-content: space-between;
   align-items: center;
   /* Alinea verticalmente los elementos */
}

.cut__column {
   flex: 1;
   height: 100%;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: top;
   align-items: center;
}

.cut__column2 {
   flex: 1;
   height: 100%;
   padding: 1rem;
   display: flex;
   flex-direction: column;
   justify-content: top;
   align-items: center;
   background-color: #FFF4E2;
}

.cut__column--text {
   
   width: 70%;
   gap: 5px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: top;
   align-items: center;
}

.cut__column--text2 {
   
   width: 90%;
   gap: 5px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}


@media screen and (min-width: 900px) {
   .auto-scroll {
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0);
   }
   
}


.cut__column--money {
   
   gap: 5px;
   height: 100%;
   width: 30%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: left;
}
.cut__column--money2 {
   gap: 5px;
   height: 100%;
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: left;
}

.cut__row--title {
   align-self: flex-start;
   color: #000;
   font-size: 1.4rem;
   font-weight: normal;
   text-align: left;
   width: 90%;
}

.cut__row--text {
   white-space: nowrap;
   align-self: flex-start;
   font-family: "Readex";
   color: #000;
   font-size: 1rem;
   font-weight: normal;
   text-align: left;
   width: 100%;
}



.cut__row--number {
   white-space: nowrap;
   align-self: flex-start;
   font-family: "Readex";
   color: #000;
   padding-left: 6px;
   font-size: 1rem;
   font-weight: normal;
   text-align: left;
   width: 100%;
}

.cut__row--green {
   align-self: flex-start;
   font-family: "Readex";
   color: #3A7011;
   padding-left: 6px;
   font-size: 1rem;
   font-weight: normal;
   text-align: left;
   width: 80%;
}

.cut__row--yellow{
   align-self: flex-start;
   font-family: "Readex";
   color: #a38b21;
   padding-left: 6px;
   font-size: 1rem;
   font-weight: normal;
   text-align: left;
   width: 80%;
}

.cut__row--red {
   align-self: flex-start;
   font-family: "Readex";
   color: #A50000;
   padding-left: 6px;
   font-size: 1rem;
   font-weight: normal;
   text-align: left;
   width: 80%;
}

.cut__offset {
   height: 27px;
}

.blank_space {
   width: 120px;
}

.input_credit {
   height: 35px;
   border-radius: 5px;
   /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
   border-style: none;
   text-align: center;
   font-size: 1.2rem;
}

.falock-container {
   width: 120px;
   display: flex;
   justify-content: center;
}

.cut-input {
   height: 40px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
   background-color: whitesmoke;
   font-family: 'readex';
   border-radius: 6px;
   border: 1px solid black;
   padding: 5px;
   font-size: 1.2rem;
   width: 100%;
   text-align: center;
}

.cut__row__input {
   display: flex;
   align-items: center;
   justify-content: space-around;
   flex: 1;
   
   
   border-radius: 20px;
   
   text-align: center;
}

.cash_icon {
   background-color: #ffffff;
   margin-top: 5px;   
   display: flex;
   justify-content: left;
   align-items: center;
}

.card_icon {
   
   margin-bottom: 5px;   
   display: flex;
   justify-content: left;
   align-items: center;
}
.falock {
   background-color: #000000;
   padding: 5px;
   border-radius: 50%;
   width: 35px;
   height: 35px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.qty__label {
   flex: 1 0 auto;
   width: 70px;
   border: none;
   outline: none;
   background-color: transparent;
   font-size: 1.2rem;
   font-weight: bolder;
   text-align: center;
}

.cut__row--small-text {
   font-size: 0.5rem;
   color: white;
}

.cut-container {
   display: flex;
   flex-direction: row;
   height: 100%;
}

.cut__card_container {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: #FFF4E2;
   width: 100%;
}

.cut__box {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 90%;
   height: 82%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

input[type=number] {
   appearance: textfield;
   -moz-appearance: textfield;
}

.cut__final-state {
   display: inline-flex;
   justify-content: center;
   margin-right: 20px;
}

.final-state__text {
   padding-left: 10px;
   padding-right: 10px;
   display: flex;
   align-items: center;
   justify-content: center;
   width: 180px;
   height: 45px;
   border-radius: 20px;
   background-color: #fff;
   font-size: 1.2rem;
   font-weight: bolder;
   text-align: center;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cut-instructions {
   color: rgb(255, 255, 255);
   
   font-size: 1.4rem;
}

.cut_final_container {
   position: relative;
   display: flex;
   align-content: center;
   width: 100%;
   align-items: center;
   justify-content: center;
   
}

.info-button-container {
   display: inline-flex;
   align-items: center;
}

.info-button {
   border: none;
   display: inline-flex;
   align-items: center;
   justify-content: center;
   padding: 5px;
   border-radius: 50%;
   cursor: pointer;
   font-size: 24px;
   background-color: white;
   color: #098d48;
   outline: none;
   width: 40px;
   height: 40px;
   margin-right: 10px;
}

.info-message {
   font-family: 'readex';
   position: absolute;
   top: 90%;
   left: 50%;
   transform: translateX(-50%);
   display: none;
   background-color: #f9f9f9;
   padding: 12px;
   width: 80%;
   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
   border-radius: 4px;
   font-size: 1rem;
   text-align: center;
}

.info-button:focus+.info-message,
.info-message.show {
   display: inline-block;
}

.cut__buttons {
   width: 100%;
   background-color: black;
   justify-content: center;
   border-radius: 0px 0px 11px 11px;
   align-items: center;
   display: flex;
   justify-content: flex-end;
   padding: 16px;
}

.cut-button {
   padding: 0 18px;
   height: 50px;
   font-size: 1.8rem;
   white-space: nowrap;
}

.cut__text {
   font-family: 'readex';
   font-size: 1.2rem;
   font-weight: normal;
}

.cut-table {
   display: flex;
   flex-direction: column;

}

.cut-row {
   display: flex;
   background-color: #FFF4E2;
   margin-bottom: 8px;
   border-radius: 10px;
   overflow: hidden;
   margin-left: 10px;
   margin-right: 10px;
   cursor: pointer;

   &:hover {
      transform: translateY(-1px);
   }
}

.cut-header {
   background-color: black;
   color: white;
   position: sticky;
   top: 0;
   z-index: 10;

   width: 100%;
   border-radius: 0;
   border-top-right-radius: 10px;
   margin-left: 0;
   margin-right: 0;

}

.cut-cell {
   flex: 1;
   padding: 10px;
   text-align: center;
}

.cut-header-cell {
   font-family: 'bebas';
   font-size: 1.4rem;
}

@media screen and (max-width: 725px) {
   .cut-header-cell {
      font-family: 'bebas';
      font-size: 1.1rem;
   }
}

.cut-cell:not(.cut-header-cell) {
   font-size: 1rem;
   font-family: 'readex';
}

.cut-cell:first-child {
   border-top-left-radius: 10px;
   border-bottom-left-radius: 10px;
}

.cut-cell:last-child {
   border-top-right-radius: 10px;
   border-bottom-right-radius: 10px;
}

@media screen and (max-width: 725px) {
   .cut-cell:not(.cut-header-cell) {
      font-size: .7rem
   }
}

.green-style {
   color: #098d48;
}

.red-style {
   color: red;
}

.blue-style {
   color: blue;
}