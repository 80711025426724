.entries-father {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 6px;
    border-top-left-radius: 0;
    height: calc(96% - 2rem);
    width: 100%;
    overflow: hidden;
    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4), -4px -4px 8px rgba(0, 0, 0, 0.1);
}

.date-entry-label {
    overflow: hidden;
}

.date-entry-button {
    font-family: 'bebas';
    min-height: 32px;
    width: 25%;
    min-width: 200px;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    gap: 0.5rem;
}

@media screen and (max-width: 1200px){
    .date-entry-button{
        min-width: 130px;
    }
}

@media screen and (max-width: 800px){
    .date-entry-button{
        min-width: 100px;
    }

    .date-entry-container {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
}

.date-entry-button:hover {
    opacity: 80%;
}

.date-entry-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px whitesmoke;
}

.entry-row {
    display: flex;
    flex-direction: row;
    font-family: 'Readex';
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.entry-row-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF4E2;
    border-radius: 10px;
    border-bottom: 0.4rem solid white;
}

.entry-row td {
    background-color: #FFF4E2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5vh;
    flex: 1;
    padding-inline: 15px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entry-row td div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entry-row-pair td {
    background-color: #c3c3c3;
}

.entry-row-mobile td {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 5vh;
    line-height: 5vh;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'readex';
    font-size: 0.9rem;
}

.entry-row-mobile td div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.date-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 999;
}

.date-entry-container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    
    gap: 1rem;
}

/* Calendar styles */
.date-picker-container {
    border-radius: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(2px);
}

.react-calendar {
    min-width: 300px;
    max-width: 400px;
    background: white;
    font-family: Arial, sans-serif;
    border-radius: 1rem;
}

.react-calendar__viewContainer {
    padding: 1rem;
}

.react-calendar__navigation {
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    padding-inline: 3rem;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.entry-row .display-none-phone {
    @media screen and (max-width: 580px) {
        display: none;
    }
}
.react-calendar .react-calendar__navigation button {
    color: white;
}

.react-calendar__navigation__label {
    font-family: 'bebas';
    font-size: 1.4rem;
}

.react-calendar__month-view__weekdays__weekday {
    flex: 1;
    text-align: center;
    box-sizing: border-box;
}

.react-calendar__month-view__weekdays__weekday abbr[title] {
    font-family: 'bebas';
    font-size: 1.2rem;
    text-decoration: none !important;
}

.react-calendar__tile {
    box-sizing: border-box;
}

.react-calendar__month-view__days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.react-calendar__tile {
    padding: 10px;
    max-height: 50px;
    box-sizing: border-box;
}

.react-calendar__navigation__arrow {
    color: black !important;
    font-size: 18px;
    font-weight: bold;
    background-color: white !important;
    padding: 5px 10px;
    height: fit-content;
    border-radius: 1rem;
    margin-top: 0 !important;
}

.date-picker-container button {
    background-color: white;
    color: black;
    border: none;
    cursor: pointer;
    margin-top: 10px;
}

.react-calendar button {
    color: black;
}

.react-calendar .react-calendar__tile--active,
.react-calendar__tile--range {
    background-color: gold;
    font-weight: 900;
}

.calendar-close-button {
    border-radius: 6rem;
    position: absolute;
    top: 0px;
    right: 6px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.react-calendar .react-calendar__navigation button {
    background-color: black;
}

.highlight-today {
    position: relative;
}

.highlight-today:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    border: 2px solid black;
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 1;
}

.react-calendar__tile--active.react-calendar__tile--rangeStart,
.react-calendar__tile--range.react-calendar__tile--rangeStart {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.react-calendar__tile--active.react-calendar__tile--rangeEnd,
.react-calendar__tile--range.react-calendar__tile--rangeEnd {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

@media screen and (max-width: 800px){
    
    .entry-row  {
        font-size: 8pt;
    }
}