.register-card {
   /*Display stuff*/
   display: flex;
   flex-direction: column;
   align-items: center;
   /* justify-content: space-around; */
   /*Box model stuff*/
   width: 100%;
   height: 100%;
   border-radius: 9px;
   background-color: #000;
   /* padding: 1rem; */
   box-sizing: border-box;
}

.container_method__icons {
   width: 100%;
}

.method__icons {
   width: 100%;
   display: flex;
   justify-content: space-around;
   align-items: center;
   gap: 2rem;
}

.register-button-row {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   font-size: 10px;
}

.register-header {
   color: rgb(255, 255, 255);
   font-size: 1.3rem;
   font-weight: bolder;
   margin: 0px;
   margin-top: 10px;
   padding: 0 0 1rem 0;
}

.register-body {
   align-items: center;
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
   width: 100%;
   background-color: #fff;
}

.method {
   align-items: center;
   display: flex;
   width: 100%;
   height: 25%;
}

.method__icons {
   display: flex;
   justify-content: center;
   width: 100%;
}

.method__button {
   background: transparent;
   border: none;
   width: 20%;
}

.method__button:hover {
   box-shadow: inset 0px 0.5px 6px 1px whitesmoke;
}

.method__button:active {
   box-shadow: inset 0px 0.5px 6px 1px black;
}

.selected {
   background-color: whitesmoke;
   border-radius: 10px;
   /* border-radius: 10px 10px 0px 0px; */
}

.method__instruction {
   /*Box model stuff*/
   justify-content: center;
   width: 100%;
   padding: 1rem;
   overflow: hidden;
   box-sizing: border-box;
   height: auto;
   background-color: white;
   /*Typography stuff*/
   text-align: center;
}

.method__instruction p {
   color: #000;
   font-size: large;
   text-align: center;
   width: 100%;
}

.customer-rows {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 70%;
}

.register__icon {
   width: 26px;
   /* establece el ancho del icono en 50px */
   height: 26px;
   /* establece el alto del icono en 50px */
   min-width: 26px;
   /* asegura que el ancho mínimo sea 50px */
   min-height: 26px;
   /* asegura que el alto mínimo sea 50px */
   font-size: 26px;
   /* para iconos de fuente, establece el tamaño de la fuente */
}

.customer-row {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: center;
   padding-left: 10px;
   /*Box model stuff*/
   height: 5vh;
   width: 100%;
   border-radius: 20px;
   background-color: white;
   border: solid 1.5px #000;
}

.customer-row__icon {
   width: auto;
   /* establece el ancho del icono en 50px */
   height: 26px;
   /* establece el alto del icono en 50px */
   min-width: 26px;
   /* asegura que el ancho mínimo sea 50px */
   min-height: 26px;
   /* asegura que el alto mínimo sea 50px */
   font-size: 26px;
}

.customer-receive__icon {

   right: 115px;

   width: 50px;
   /* establece el ancho del icono en 50px */
   height: 50px;
   /* establece el alto del icono en 50px */
   min-width: 50px;
   /* asegura que el ancho mínimo sea 50px */
   min-height: 50px;
   /* asegura que el alto mínimo sea 50px */
   font-size: 50px;
   /* para iconos de fuente, establece el tamaño de la fuente */

}

.customer-row__input {
   /*Box model stuff*/
   border: none;
   outline: none;
   background: transparent;
   /*Typography stuff*/
   font-size: 16px;
   font-weight: bold;
   text-align: center;
   color: black;
}

.customer-row__button {
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
   background-color: whitesmoke;
   border-radius: 16px;
   border: none;
   cursor: pointer;
   padding-top: 6px;
   padding-bottom: 2px;
}

.clean-fields {
   background: none;
   border: none;
   cursor: pointer;
   outline: none;
}

.clean-fields:hover {
   box-shadow: inset 0px 0.5px 6px 1px whitesmoke;
}

.clean-fields:active {
   box-shadow: inset 0px 0.5px 6px 1px black;
}

.register-qty-rows {
   display: flex;
   flex-direction: column;
   height: 220px;
   width: 100%;
   justify-content: space-between;
   align-items: center;
   background-color: #000;
}

.register-subtotal {
   align-items: center;
   display: flex;
   justify-content: space-between;
   width: 70%;
}

.register-total {
   align-items: center;
   display: flex;
   justify-content: space-between;
   width: 70%;
}

.register__text {
   color: whitesmoke;
   font-size: 1.4rem;
   font-weight: bold;
}

.register__text--comission {
   color: whitesmoke;
   font-size: 15px;
   font-weight: bold;
}

.register__input-box {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: space-around;
   /*Box model stuff*/
   width: 50%;
   padding-top: 8px;
   padding-bottom: 8px;
   border-radius: 10px;
   background-color: whitesmoke;
   /*Miscellaneous*/
   overflow: hidden;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.subtotal__input {
   /*Box model stuff*/
   width: 90px;
   border: none;
   outline: none;
   background: transparent;
   /*Typography stuff*/
   font-size: 1.4rem;
}

.total__input {
   /*Box model stuff*/
   width: 90px;
   border: none;
   outline: none;
   background: transparent;
   /*Typography stuff*/
   font-size: 1.4rem;
}

.card-buttons {
   display: flex;
   gap: 30px;
   justify-content: space-around;
   align-items: center;
   width: 100%;
   height: 15%;

}

.card-buttons button {
   width: 30%;
}

.button--go-back {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: space-around;
   /*Box model stuff*/
   width: 150px;
   padding: 8px 16px;
   border: none;
   border-radius: 25px;
   background-color: whitesmoke;
   /*Typography stuff*/
   font-size: 1.2rem;
   font-weight: bold;
   color: #F44336;
   /*Miscellaneous*/
   cursor: pointer;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.button--save {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: space-between;
   /*Box model stuff*/
   width: 150px;
   padding: 8px 16px;
   border: none;
   border-radius: 25px;
   background-color: whitesmoke;
   /*Typography stuff*/
   font-size: 1.2rem;
   font-weight: bold;
   color: #04c055;
   /*Miscellaneous*/
   cursor: pointer;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.button--save-disabled {
   background-color: whitesmoke;
   color: #808080;
   cursor: pointer;
}

.hover-shadow:hover {
   box-shadow: 0px 1px 1px 0.5px;
   opacity: 1;
}

.click-shadow:active {
   box-shadow: 0px 2px 1px 1px;
}

.register-title {
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   font-size: 2rem;
   font-weight: normal;
   padding: 0.5rem;
   margin: 0;
   height: 9%;
   width: 100%;
}

.register-payment {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background-color: #D9D9D9;
   flex-grow: 1;
   /* max-height: 100%; */
   width: 100%;
   height: 70%;
   gap: 1em;
}

.register-button-row .charge {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: center;
   /*Box model stuff*/
   height: 64px;
   width: 40%;
   border: none;
   border-radius: 9px;
   /*Typography stuff*/
   font-size: 2rem;
   font-weight: normal;
   color: rgb(0, 0, 0);
   /*Miscellaneous*/
   cursor: pointer;
   gap: 1rem;
   box-sizing: border-box;
}

.register-button-row .receipt-cash-box-button {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: center;
   /*Box model stuff*/
   height: 64px;
   width: 64px;
   border: none;
   border-radius: 15px;
   background-color: white;
   /*Miscellaneous*/
   cursor: pointer;
}

.register-button-row .receipt-cash-box-button:hover {
   opacity: 90%;
}

.register-change {
   color: rgb(255, 255, 255);
   font-size: 1.6rem;
   font-weight: normal;
   padding: 0.8rem;
   margin: 0;
   width: 100%;
   height: 10%;
   background-color: #000;
}

.register-options {
   color: rgb(255, 255, 255);
   display: flex;
   font-size: 1.5rem;
   font-weight: normal;
   justify-content: center;
   align-items: center;
   margin: 0;
   width: 100%;
   background-color: #000;
   padding-bottom: 1.2rem;
   border-radius: 0px 0px 9px 9px;
}

.register-payment {
   display: flex;
   padding: 2% 0%;
   align-items: center;

}

.field {
   background-color: #fff;
   display: flex;
   align-items: center;
   justify-content: space-around;
   width: 80%;
   height: 19%;
   border-radius: 9px;
   flex-wrap: wrap;
}

.toggle-payment-mode {
   cursor: pointer;
   background-color: white;
   border-radius: 12px;
   font-family: 'bebas';
   font-size: 1.2rem;
   padding: 8px;
   display: flex;
   align-items: center;
   gap: 8px;

   &:hover {
      opacity: 90%;
   }
}

@media screen and (max-width: 970px) {
   .payment-method {
     padding: 10px 10px; /* Adjusted font size for smaller screens */
   }
 }

.payment-method {
   padding: 10px 25px;
   min-width: 120px;
}

@media screen and (max-width: 1120px) {
   .payment-method {
     padding: 10px 0px; /* Adjusted font size for smaller screens */
   }
 }


.money-field {
   width: 70%;
   display: flex;
   margin-top: auto;
   height: 80%;
}

.payment-title {
   height: 29%;
   width: 100%;
   font-size: 0.7em;
   display: flex;
   justify-content: left;
   color: rgb(9, 8, 8);
   /* background-color: #04c055; */
   padding: 0% 3%;
}

.payment-title h3 {
   color: rgb(136, 128, 128);
   font-weight: normal;
   font-size: 1.2rem;
}

.payment-field {
   height: 71%;
   width: 100%;
   border: 3px solid gray;
   border-radius: 7px;
   padding: 0%;
   display: flex;
   align-items: center;
}

.payment-field input {
   font-family: 'readex';
   font-size: 1rem;
}

.register-credito {
   height: 37%;
}

.register-credito .money-field {
   height: 40%;
}

.register-user-field {
   height: 73%;
   width: 80%;
   border: 2px solid #676464;
   border-radius: 20px;
   padding: 0%;
   display: flex;
   align-items: center;
   gap: 10px;
   padding-left: 1rem;
   padding-right: 1rem;
}

.user-info-inner{
   height: 80%;
   width: 35%;
}

.register-user-points{
   height: 73%;
   width: 100%;
   border: 2px solid #676464;
   border-radius: 20px;
   padding: 0%;
   display: flex;
   align-items: center;
   gap: 10px;
   padding-left: 1rem;
   padding-right: 1rem;
}

.user-info {
   width: 90%;
   gap: 20px;
   display: flex;
   justify-content: center;
}


.user-info input {
   font-family: 'readex';
   font-size: 0.9rem;
   text-align: center;
   border: none;
   outline: none;
   background-color: transparent;
   width: 100%;
   padding-top: 8px;
   padding-bottom: 8px;
}

.user-checkbox{
   font-family: 'readex';
   width: 90%;
   gap: 20px;
   display: flex;
   justify-content: center;
   color: gray;
   padding: 5px;
}

.user-checkbox input{
   cursor: pointer;
}

.user-credit {
   width: 90%;
   gap: 20px;
   display: flex;
   justify-content: center;
}

.credit-input {

   border: none;
   outline: none;
   background-color: transparent;
   /* Add any additional styles you want */
}

.register-missing {
   padding: 4px 25px;
   background-color: transparent;
}
@media screen and (max-width: 1070px) {
   .register-missing {
     padding: 4px 0px; /* Adjusted font size for smaller screens */
   }

 }

 @media screen and (max-width: 920px) {
   .register-missing {
     padding: 2px 0px; /* Adjusted font size for smaller screens */
   }

 }

.prueba{
   font-size: 10px;
}

.register-missing-text {
   border: none;
   outline: none;
   font-size: 2rem;
   background-color: #fff;
   height: 65%;
   width: 60%;
   border-radius: 9px;
   color: rgb(136, 128, 128);
   text-align: center;
   display: flex;
   align-items: center;
   justify-content: center;
}

.register-missing p {
   font-size: 2.2rem;
}

@media screen and (max-width: 1200px) {

   .register-missing p{
      font-size: 1.7rem;
   }
 }

 @media screen and (max-width: 950px) {

   .register-missing p{
      font-size: 1.55rem;
   }
 }

 @media screen and (max-width: 800px) {

   .register-missing p{
      font-size: 1.3rem;
   }
 }

 @media screen and (max-width: 800px){
   .register-missing p {
    font-size: 1.0rem;
   }
 }



.register-change {
   display: flex;
   flex-direction: row;
   justify-content: right;
   align-items: center;
   font-size: 2.5rem;
   width: 100%;
   height: 10%;
}

.register-card div{
   /* min-width: 260px; */
}

.register-change-text {
   /* width: 100%; */
   color: white;
   background-color: transparent;
   font-size: 1em;
   font-weight: normal;
   text-align: right;
}

.payment-input {
   border: none;
   outline: none;
   background-color: transparent;
   width: 85%;
}

.receipt-user-button {
   width: 20px;
   height: 20px;
   background-color: white;
   display: flex;
   align-items: center;
   justify-content: center;
   border-radius: 15px;
   cursor: pointer;
   border: none;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.receipt-user-button:hover {
   opacity: 80%;
}

.receipt-user-button-icon{
   border-radius: 35px;
}

.payment-field-focused {
   border-color: #D9AA08;
}

.payment-field-unfocused {
   border-color: gray;
}

.payment-field-filled {
   border-color: black;
}


.icon{
   width: 50px;
   height: 50px;
}

@media screen and (max-width: 1300px) {
   .icon {
     width: 28px;
     height: 28px;
   }
   .register-missing-text{
      font-size: 1.6rem;
   }
   .register-change-text {
      font-size: 1.8rem;
   }
 }

@media screen and (max-width: 1000px) {
   .icon {
     width: 28px;
     height: 28px;
   }
 }

 @media screen and (max-width: 850px) {
   .payment-title h3 {
      font-size: 0.7rem;
   }
 }

 @media screen and (max-width: 850px) {
   .register-missing-text{
      font-size: 1.4rem;
   }


   .golden-button.reduce-golder{
      min-width: 100px;
   }
 }

 @media screen and (max-width: 700px){
   .register-change-text {
      font-size: 1.4rem;
   }
 }

 @media screen and (max-width: 1135px){
   .register-title{
      font-size: 1.1rem;
   }
 }

 @media screen and (max-width: 950px) {
   .register-button-row .charge {
      font-size: 1rem;
   }
 }


