.app {
  height: 100vh;
  width: 100vw;
  max-height: 100vh;
  max-width: 100vw;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Color de fondo semi-transparente */
  backdrop-filter: blur(8px); /* Ajusta el valor para controlar el desenfoque */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Asegúrate de que esté por encima de todo */
}

.custom-ui {
  background: #ffffff;
  border-radius: 5px;
  width: 400px;
  padding: 20px;
  text-align: center;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
  z-index: 10000;
}

.custom-ui h1 {
  font-size: 1.6rem;
  margin-bottom: 10px;
  color: #333;
}

.custom-ui p {
  font-family: 'readex';
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: #555;
}

.custom-ui-buttons {
  display: flex;
  justify-content: space-between;
}

.custom-ui button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: normal;
  outline: none;
}

.custom-ui button:hover {
  opacity: 0.8;
}

.custom-ui button:first-child {
  background-color: gray;
  color: #fff;
}

.custom-ui button:last-child {
  background-color: #d06154;
  color: #fff;
}

@keyframes gradientMovement {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}

.background-animation {
  animation: gradientMovement 3s linear infinite;
  background: -webkit-linear-gradient(0deg, #ffd700, #fea 25%, #ffffff 38%, #ffd700 63%, #fea 87%, #ffd700);
  background-size: 200% 200%;
}

.golden-button {
  font-family: 'bebas';
  min-height: 32px;
  min-width: 100px;
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  gap: 0.5rem;
  background: -webkit-linear-gradient(-60deg, #ffd700, #fea 45%, #ffffff 60%, #fea 25%, #ffd700);
}

.golden-button:hover {
  background: -webkit-linear-gradient(-45deg, #ffd700, #fea 25%, #ffffff 38%, #ffd700 63%, #fea 87%, #ffd700);
}

.silver-button {
  font-family: 'bebas';
  min-height: 32px;
  min-width: 100px;
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  gap: 0.5rem;
  background: -webkit-linear-gradient(-60deg, #C0C0C0, #E0E0E0 45%, #FFFFFF 60%, #E0E0E0 25%, #C0C0C0);
}

.silver-button:hover {
  background: -webkit-linear-gradient(-45deg, #C0C0C0, #E0E0E0 25%, #FFFFFF 38%, #C0C0C0 63%, #E0E0E0 87%, #C0C0C0);
}

table {
  border-spacing: 0;
}

.Dropdown-control {
  height: 40px;
}

.text-yellow {
  color: #ffd700;
}