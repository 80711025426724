.add-entry-father {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.add-entry-container {
    background-color: #FFF4E2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-bottom: 60px;
}

.add-entry-card {
    display: flex;
    flex-direction: column;
    padding: 0rem;
    width: calc(100% - 2rem);
    max-width: 1200px;
}

.add-entry-left-column {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add-entry-left-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.add-entry-header-column {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.add-entry-search-header {
    font-size: 1.6rem;
    font-weight: normal;
    margin: 0;
}

.add-entry-search-bar-article {
    height: auto;
    min-height: 40px;
    min-width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.add-entry-search-bar-article input[type='text'] {
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
}

.add-entry-quantity-input {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-entry-quantity-input input[type='text'] {
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: white;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
    text-align: center;
    width: 100px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    margin: 5px 0px;
}

.add-entry-text-field {
    width: 100%;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
    display: flex;
}

.add-entry-text-field textarea {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    font-family: 'Readex';
    outline: none;
    box-sizing: border-box;
    resize: none;
    border-radius: 6px;
}

.add-entry-text-field input[type='text'] {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: transparent;
    font-size: 1.2rem;
    font-family: 'Readex';
    outline: none;
    box-sizing: border-box;
    border-radius: 6px;
}

.add-entry-button {
    font-family: 'bebas';
    min-height: 32px;
    min-width: 200px;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    gap: 0.5rem;
    padding-left: 2rem;
    padding-right: 2rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.add-entry-button:hover {
    background-color: whitesmoke;
}

.add-entry-table-box {
    background-color: white;
    width: 100%;
    border-radius: 6px;
    text-align: center;
    overflow-y: auto;
    display: flex;  
    margin-top: 1rem;
    justify-content: center;
    overflow-x: hidden;
    min-height: 500px;
    border: none;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
}

.add-entry-table-box table {
    width: 100%;
}

.entry-table-header {
    background-color: black;
    color: white;
    font-weight: 100;
}


.add-entry-right-column {
    display: flex;
    flex-direction: column;
    width: 30%;
    padding: 2rem;
}

.add-entry-right-top {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.add-entry-scroll {
    flex: 0.9;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.custom-dropdown-option {
    font-size: 1.8;
}

.date-add-entry-button {
    font-family: 'bebas';
    min-height: 32px;
    min-width: 200px;
    width: 100%;
    font-size: 1.3rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    gap: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.date-add-entry-button:hover {
    opacity: 80%;
}

.date-add-entry-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px whitesmoke;
}

.add-entry-button-delete {
    background-color: crimson;
    display: flex;
    font-family: 'bebas';
    color: white;
    gap: 10px;
    justify-content: center;
    flex: 1;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.add-entry-button-save {
    background-color: gold;
    display: flex;
    font-family: 'bebas';
    color: black;
    gap: 10px;
    justify-content: center;
    flex: 0.8;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.add-entry-button-cancel {
    background-color: gray;
    display: flex;
    font-family: 'bebas';
    justify-content: center;
    gap: 10px;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.add-entry-header-column-delete {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.add-entry-button-resize {
    flex: 0.48;
}

.add-entry-right-top .add-entry-button-delete,
.add-entry-right-top .add-entry-button-save,
.add-entry-right-top .add-entry-button-cancel {
    padding: 8px 16px !important;
    min-width: auto;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1.6rem;
}

.dropdown-button {
    width: 40px;
}

.date-entry-label-center {
    color: #555;
}

.add-entry-row {
    display: flex;
    flex-direction: row;
    font-family: 'Readex';
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;

    &:hover {
        background-color: blanchedalmond;
    }
}

.add-entry-row-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 1rem;
}

.add-entry-row td {
    background-color: #FFF4E2;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 5vh;
    line-height: 5vh;
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-entry-row-mobile td {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 5vh;
    line-height: 5vh;
    width: 20%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-entry-table-button {
    padding: 12px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    border: none;
}

.add-entry-table-box-container {
    background-color: black;
    border-radius: 6px;
    margin-top: 18px;
    padding: 10px;
    padding-bottom: 0;
    padding-top: 0;
}

.add-entry-table-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background-color: white;
    border-radius: 0px;
    padding: 0px;
    overflow-y: auto;
    gap: 10px;
    max-height: 500px;
}

.add-entry-header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: black;
    color: white;
}

.add-entry-header {
    flex: 1;
    font-weight: normal;
    font-size: 1.4rem;
}

.add-entry-row {
    background-color: #FFF4E2;
    border-radius: 10px;
    display: flex;
    width: calc(100% - 1rem);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.add-entry-row>div {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.add-entry-table-button {
    padding-inline: 10px;
    margin: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.add-entry-total-bottom {
    text-align: right;
    font-weight: normal;
    color: white;
    margin: 10px;
}

.add-entry-right-column {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* Cambiado a 100% */
    padding: 2rem;
}

.add-entry-right-top {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* Cambiado de row a column */
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 6px;
}

/* Añadiendo media queries */
@media (min-width: 800px) {
    .add-entry-container {
        padding: 0;
    }
    
    .add-entry-father {
        flex-direction: row;
        /* Cambiado de column a row para pantallas más grandes */
    }

    .add-entry-card {
        flex-direction: row;
        width: 92%;
        padding: 1;
    }

    .add-entry-left-column {
        width: 70%;
    }

    .add-entry-right-column {
        width: 30%;
        padding: 2rem;
    }

    .add-entry-left-top {
        flex-direction: row;
    }
}

@media (max-width: 1200px) {
    .add-entry-button {
        font-size: 1rem;
    }

    .date-add-entry-button {
        font-size: 1rem;
    }

    .add-entry-right-top {
        flex-direction: column;
    }

    .add-entry-right-column {
        padding: 1rem;
    }
}