.utility-monthly-content .MuiBarElement-root:nth-of-type(1) {
    fill: crimson;
}

.utility-monthly-content .MuiBarElement-root:nth-of-type(2) {
    fill: goldenrod;
}

.utility-monthly-content .MuiBarElement-root:nth-of-type(3) {
    fill: cadetblue;
}

.utility-monthly-content .MuiBarElement-root:nth-of-type(3) {
    fill: darkorange;
}

.utility-monthly-content .MuiBarElement-root:nth-of-type(3) {
    fill: green;
}