.settings-father {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.settings-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.gray-container{
    display: flex;
    flex-direction: column;
    
    width: 100%;
    padding: 1rem;
    border-radius: 0px 0px 16px 16px;
    background-color: #A0A0A0;
    
    overflow-y: auto;
    box-sizing: border-box;
}

.white-container{
    display: flex;
    flex-direction: column;
    
    width: 100%;
    padding: 1rem;
    border-radius: 16px;
    background-color: #ffffff;  
    margin-bottom: 0px;
    
    
}


.settings-row-column{
    flex-direction: row;
    display: flex;
   
    width: 100%;
}
.settings-child{
    flex: 1;
    padding-bottom: 20px;
}

.settings-text {
    color: black;
    font-size: 1.1rem;
    font-family: "Readex";
}

.settings-top-row{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.settings-h1{
    color: white;
    font-size: 1.6rem;
    padding: 15px;
}

.settings-box {
    display: flex;   
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 90%;
    border-radius: 16px;
    
    background-color: black;
    
}

.settings-title {
    font-size: 2rem;
    color: black;
}

.settings-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}
.settings-row2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.settings-input {
    font-family: 'readex';
    border-radius: 15px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 1rem;
    width: 150px;
}

.settings-input:disabled {
    background-color: #f4f4f4;
    color: #000000;
    cursor: not-allowed;
    opacity: 0.7;
}

.stripe{
    width: 150px;
}

.setting-button {
    font-family: 'bebas';
    border: none;
    background-color: white;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1.2rem;
    
    width: fit-content;
    padding: 7px;
    margin-right: 1rem;
}

.setting-button:hover {
    opacity: 0.8;
}

.settings-green-text {
    color: #3A7011;
    font-weight: bold;
}

.settings-red-text {
    color: #A50000;
    font-weight: bold;
}

.settings-normal-text {
    font-family: 'readex';
    font-size: 1rem;
    color: #000000;
    text-align: justify;

    margin: 20px;
}

@media (max-width: 768px) {
    .settings-row-column  {
        flex-direction: column;
    }
    .stripe{
        display: none;
    }
    .settings-row2{
        flex-direction: column;
    }
    .settings-normal-text {
        font-size: 0.8rem;
    }

    .settings-father {
        overflow-y: auto;
        height: auto;
        padding-top: 40px;
        padding-bottom: 80px;
    }
}