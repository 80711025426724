.utility-cashier-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 300px;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0);
}

.utility-cashier-row {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px;
    height: 48px;
    width: 90%;
    text-align: center;
    font-family: 'readex';
}

.utility-scroll  {
    width: 120px;
    height: 100%;
    padding: 6px;
    cursor: pointer;
    padding-left: 6px;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'readex';
    border-radius: 6px;
}
.utility-scroll2{
    width: auto;
    height: 100%;
    padding: 6px;
    cursor: pointer;
    padding-left: 6px;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'readex';
    border-radius: 6px;
}
.utility-cashier-grid-column {
    flex: 1;
}
.utility-cashier-grid-column2 {
    flex: 1;
}

.utility-cashier-header {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    border-radius: 10px;
    padding: 10px;
    height: 48px;
    width: 90%;
    color: white;
    text-align: center;
    font-size: 1.2rem;
}

.utility-cashier-button {
    border: 1px solid black;
    
    &:hover {
        background-color: #f2f2f2;
        opacity: 100%;
    }
}

@media (max-width: 550px) {
    .utility-cashier-grid-column2 {
        display: none;
    }
}