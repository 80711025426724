.add-product-father {
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media(max-width: 600px){
        height: auto;
        padding-top: 20px;
        padding-bottom: 60px;
    }
}

.add-product-father-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
    overflow-y: auto;
}

.add-product-card {
    background-color: #FFF4E2;
    border-radius: 8px;
    padding: 0px 0px 16px 0px;
    width: 60%;
    
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);

    @media(max-width: 780px){
        width: 70%;
    }

    @media(max-width: 670px){
        width: 80%;
    }

    @media(max-width: 900px){
       margin-bottom: 45px; 
    }
    
}

.add-product-header-text {
    color: white;
    font-size: 1.6rem;
    font-weight: normal;
    background-color: black;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-top: 0;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    width: 100%;

    @media(max-width: 900px){
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 1.3rem;
    }
}

.add-product-row {
    padding-top: .7rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    margin-left: 16px;
    margin-right: 16px;

    @media(max-width: 900px){
        padding-top: .4rem;
    }
}

.add-product-row-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    @media(max-width: 900px){
        gap: 7px    ;
    }
}

.photo-product{
    width: 192px;
    height: 192px;
}

.add-product-image {
    background-color: white;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40vh;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    gap: 6px;
}

.add-product-image-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    max-width: 90%;
    max-height: 80%;
}

.add-product-image-button img {
    border-radius: 6px;
    max-height: 100%;
    max-width: 100%;
}

.add-image-button {
    display: none;
}

.add-product-image:hover {
    cursor: pointer;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
}

.file-upload-container {
    border-radius: 16px;
    width: 80%;
}

.file-upload-container label {
    display: block;
    cursor: pointer;
    width: 100%;
    height: 100%;
    font-size: 1.6rem;
    text-align: center;
}

.file-upload-container input[type="file"] {
    display: none;
}

.right-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    gap: 10px;

    @media(max-width: 1200px){
        gap: 4px;
    }
}

.add-product-input {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1rem;
    font-family: "readex";

    @media(max-width: 1200px){
        font-size: 0.8rem;
        padding: 2px;
        padding-left: 10px;
    }
}
.add-productDesc-input {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1rem;
    font-family: "readex";

    @media(max-width: 1200px){
        font-size: 0.8rem;
        padding: 0px;
        padding-left: 10px;   
    }
}

.add-productDesc-input #text-areaDesc{

    @media(max-width: 1200px){

        font-size: 1rem !important;
        padding: 6px 0.8rem !important;

    }

}

.little-add-product-input {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-family: 'readex';
}

.add-product-input input[type="text"] {
    flex: 1;
    padding: 6px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    font-family: "readex";

    @media(max-width: 1200px){
        font-size: 1rem;
        padding: 6px;
    }



}



.little-add-product-input input[type="text"] {
    width: 50%;
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    font-family: "readex";

    @media(max-width: 900px){
        padding: 5px;
    }
}

.drop-row {
    font-weight: normal;
    display: flex;
    flex-direction: row;
    font-family: "readex";
    justify-content: space-between;
    width: 100%;
}

.drop-row .add-product-input {
    width: 84%;
    font-weight: normal;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    font-family: "readex";
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.add-product-department-scroll {
    width: 100%;
    font-weight: normal;
}

.add-product-department-scroll .Dropdown-control {
    border-radius: 10px;
    border: none;
    text-align: left;
    font-weight: normal;
    font-family: "readex";
    width: auto;

    @media(max-width: 600px){
        font-size: .7rem;
    }
}

.product-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;

    @media(max-width: 1200px){
        margin-top: 8px;
    }

    @media(max-width: 900px){
        margin-top: 2px;
    }
}

.add-product-column {
    display: flex;
    flex-direction: column;
    gap: 10px;


}

.add-product-offer-button {
    background-color: white;
    width: 100%;
    font-weight: normal;
    font-size: 1.4rem;
    gap: 10px;
    color: #d1b104;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    @media(max-width: 900px){
        font-size: 1.1rem;
        padding: 4px 16px;
    }
}

.add-product-cancel-button {
    background-color: white;
    width: 47%;
    font-weight: normal;
    font-size: 1.4rem;
    gap: 10px;
    color: #d06154;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    @media(max-width: 1380px){
        font-size: 1.1rem;
        padding: 5px 4px;
    }
}
  
.add-product-save-button {
    background-color: white;
    width: 47%;
    color: #04c055;
    font-weight: normal;
    font-size: 1.4rem;
    gap: 10px;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

    @media(max-width: 1380px){
        font-size: 1.1rem;
        padding: 5px 4px;
    }
}

@media (max-width: 1080px) {
    .icon-buton {
      display: none;
    }
  }
  
.cancel-button svg,
.save-button svg {
    margin-right: 6px;
}

.dropdown-button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 10%;
    background-color: white;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-weight: normal;
}

.box{
    width: 30px;
}

@media (max-width: 1400px) {
    .add-product-card {
        width: 75%;
    }
}

@media (max-width: 1200px) {
    /* .add-product-card {
        width: 90%;
    } */

    .add-product-row-button {
        flex-direction: column;
    }

    .little-add-product-input {
        width: 100%;
    }
}

.edit-product-text-area {
    font-size: 1rem;
}

@media (max-width: 900px) {
    .add-product-father-container {
        padding-block: 20px;
    }
}

@media (max-width: 900px) {
    .add-product-image {
        height: 20%;
    }

    .add-product-image {
        width: 90%;
    }

    .right-container {
        width: 90%;
    }

    .add-product-row {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }

    .add-product-card {
        width: 90%;
    }

    .edit-product-text-area {
        font-size: 0.8rem;
    }

    .add-product-input {
        font-size: 0.8rem;
    }


    .little-add-product-input input[type="text"] {
        font-size: 0.8rem;
    }

    .file-upload-container {
        color: gray;
        background-color: white;
    }

    .photo-product{
        width: 100px;
        height: 100px;
    }

    .edit-product-father .Dropdown-control {
        font-size: 0.8rem;
    }
}