.father-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #fff;
    
}

.parent-card{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-card {
    background-color: #ed6609;
    border-radius: 9px;
    /* padding: 16px 18px 16px 18px; */
    min-width: 200px;
    width: 560px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
    gap: 10px;
}

.card-title{
    width: 100%;
    background-color: #000;
    border-radius: 16px 16px 0px 0px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6vh;
}

.card-title h1{
    color: white;
}

.card-body{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.product-header-text {
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: bold;
    color: whitesmoke;
}

.add-user-input {
    width: 80%;
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.little-add-user-input {
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.add-user-input input[type="text"],
.add-user-input input[type="password"] {
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
}

.little-add-user-input input[type="text"] {
    width: 50%;
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
}

.add-user-dropdown {
    width: 60%;
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    margin-top: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.user-role-scroll {
    width: 100%;
}

.user-role-scroll .Dropdown-control {
    border-radius: 10px;
    border: none;
    text-align: left;
}

.user-role-scroll .Dropdown-placeholder{
    font-family: 'Readex';
    font-size: 1rem;
    
}

.user-button-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 30px;
}

.add-user-input{
    font-family: 'Readex';
}

.cancel-button {
    background-color: whitesmoke;
    width: 47%;
    font-weight: bold;
    font-size: 1.4rem;
    color: #d06154;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
  
.save-button {
    background-color: whitesmoke;
    width: 47%;
    color: #04c055;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
  
.cancel-button svg,
.save-button svg {
    margin-right: 6px;
}