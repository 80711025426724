.utility-month-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    position: relative;
}

.utility-month-header .Dropdown-menu {
    position: absolute;
    top: auto;
    bottom: auto;
    max-height: 200px;
    overflow-y: auto;
    font-size: 1rem;
    width: 100%;
}

.utility-day-scroll .Dropdown-control  {
    width: 140px;
}

.utility-month-scroll .Dropdown-control {
    width: 120px;
}

.utility-year-scroll .Dropdown-control{
    width: 70px;
}

@media (max-width: 550px) {

    .utility-hidden-text {
        display: none;
    }

    .utility-month-header {
        justify-content: space-evenly;
        gap: 0;
    }

    .utility-day-scroll .Dropdown-control{
        width: 100px;
        padding: 10px;
    }

    .utility-month-scroll .Dropdown-control {
        width: 80px;
        padding: 10px;
    }

    .utility-year-scroll .Dropdown-control {
        width: 70px;
        padding: 10px;
    }

    .utility-month-header {
        gap: 10px;
    }

}