.customer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
}

.customer-modal {
    background: white;
    padding: 18px;
    border-radius: 8px;
    width: 900px;
    max-width: 98%;
    max-height: 650px;
    position: relative;
    z-index: 1000;
    overflow: hidden;
}

.payment-modal-customer-text {
    font-size: 1.2rem;
}

.payment-modal-customer-gray {
    color: #555;
}

.payment-modal-customer-green {
    color: green;
}

.customer-paied {
    color: green !important;
    text-align: center;
}

.payment-modal-customer-tiny {
    display: block;
    font-size: 0.8rem;
}

.payment-modal-customer-yellow {
    font-weight: bold;
}

.payment-debt-button {
    border: none;
    border-radius: 9px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 6px 25px;
}

.customer-modal-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.customer-modal .modal-header {
    background-color: #000;
    color: white;
    padding: 10px 20px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.customer-modal .header-item {
    flex: 1;
    font-size: 1.4rem;
    text-align: center;
}

.customer-modal .header-item:first-child {
    flex: 0.6;
}

.customer-modal .header-item:last-child {
    flex: 1.4;
}

.customer-close-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: white;
    background-color: black;
    padding: 8px;
    border-radius: 8px;
}

.customer-payments-list {
    max-height: 300px;
    overflow-y: auto;
}

.customer-payment-item {
    font-family: 'readex';
    font-size: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    width: 100%;
    box-sizing: border-box;
}

.customer-payment-detail {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.customer-payment-detail:first-child {
    flex: 0.6;
}

.customer-payment-detail:last-child {
    flex: 1.4;
}

.customer-payments-list::-webkit-scrollbar {
    width: 6px;
}

.customer-payments-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.customer-payments-list::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.customer-payment-value {
    color: #555;
    text-align: center;
}

.customer-not-debt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-block: 16px;
    width: 100%;
}

.debt-payment-not-found {
    font-size: 1.8rem;
}

@media (max-width: 900px) {

    .customer-modal .modal-title {
        margin-bottom: 10px;
    }

    .customer-modal .header-item {
        display: flex;
        justify-content: center;
    }

    .hidden {
        display: none !important;
    }
}


/* Bills styles */
.bill-debt-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 800px;
    z-index: 1000;
}

.bill-debt-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
}

.bill-debt-modal-content {
    position: relative;
}

.bill-debt-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px 8px 0 0;
}

.bill-debt-modal-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: lighter;
}

.bill-debt-close-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: white;
    color: black;
    font-family: 'bebas';
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.bill-debt-close-button:hover {
    background-color: gray;
}

.bill-debt-details {
    margin-top: 10px;
}

.bill-debt-products-table {
    width: 100%;
    border-collapse: collapse;
}

.bill-debt-products-table th {
    font-size: 1.4rem;
    font-weight: normal;
    background-color: black;
    color: white;
}

.bill-debt-products-table th,
.bill-debt-products-table td {
    padding: 8px;
    text-align: center;
    border-bottom: 1px solid #ddd;
}

.bill-debt-products-table td {
    font-family: 'readex';
}

.bill-debt-products-table tbody tr {
    cursor: pointer;
    &:hover {
        background-color: silver;
    }
}

.bill-debt-modal-button-row {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

@media (max-width: 768px) {
    .bill-debt-modal {
        width: 90%;
        max-width: 90%;
    }
}