.section-navbar-father {
    width: 100%;
}

.section-navbar-row{
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.section-navbar-button {
    background-color: black;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: white;
    cursor: pointer;
    margin: 0;
    min-width: 14%;
    padding: 8px;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: none;
    z-index: 999;
}

.section-navbar-button:hover {
    background-color: #333;
}

.section-navbar-button-selected {
    background-color: white;
    color: black;
}

.section-navbar-button-selected:hover {
    background-color: white;
}

.section-navbar-button-inverse {
    background-color: white;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: black;
    cursor: pointer;
    margin: 0;
    min-width: 14%;
    padding: 8px;
    font-size: 1.5rem;
    text-align: center;
    text-decoration: none;
    z-index: 999;
}

.section-navbar-button-inverse:hover {
    background-color: #ddd;
}

.section-navbar-button-inverse-selected {
    background-color: black;
    color: white;
}

.section-navbar-button-inverse-selected:hover {
    background-color: black;
}

@media screen and (max-width: 1200px) {
    .section-navbar-button {
        font-size: 1rem;
    }
    .section-navbar-button-inverse {
        font-size: 1rem;
    }
}