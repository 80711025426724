.gen-container{
    display: flex;
    height: 100%;
    
    
}
.father-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.user-card {
    background-color: #FFF4E2;
    border-radius: 16px;
    /* padding: 16px 18px 16px 18px; */
    min-width: 200px;
    width: 560px;
    height: 420px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
}



.product-header-text {
    margin-top: 0;
    font-size: 1.4rem;
    font-weight: 300;
    color: white;
}

.add-user-input {
    width: 80%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.little-add-user-input {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.add-user-input input[type="text"],
.add-user-input input[type="password"] {
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    font-family: 'Readex';
}

.little-add-user-input input[type="text"] {
    width: 50%;
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
}

.add-user-dropdown {
    width: 60%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    margin-top: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.user-role-scroll {
    width: 100%;
    
}

.user-role-scroll .Dropdown-control {
    border-radius: 10px;
    border: none;
    text-align: left;
    background-color: white;
}

.user-button-container {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
    gap: 30px;
}

.cancel-button {
    background-color: white;
    width: 47%;
    font-weight: bold;
    font-size: 1.4rem;
    color: #d06154;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    margin-right: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
  
.save-button {
    background-color: white;
    width: 47%;
    color: #04c055;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
  
.cancel-button svg,
.save-button svg {
    margin-right: 6px;
}