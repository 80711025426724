.utility-top-margin-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 300px;
    overflow-y: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    scrollbar-width: thin;
    scrollbar-color: rgba(0, 0, 0, 0.3) rgba(0, 0, 0, 0);
}

.utility-top-margin-row {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px;
    height: 48px;
    width: 90%;
    text-align: center;
    font-family: 'readex';
}

.utility-grid-column {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.utility-grid-column2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
}

.utility-top-header {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    border-radius: 10px;
    padding: 10px;
    height: 48px;
    width: 90%;
    color: white;
    text-align: center;
    font-size: 1.2rem;
}

@media (max-width: 550px) {
    .utility-grid-column2 {
        display: none;
    }
}