.inventory-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 6px;
    border-top-left-radius: 0;
    height: calc(96% - 2rem);
    width: 100%;
    overflow: hidden;
    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4), -4px -4px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
}

.table-box-container {
    background-color: black;
    flex-grow: 1;
    border-radius: 6px;
    text-align: center;
    overflow-y: auto;
    display: flex;
    padding: 1rem;
    padding-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    justify-content: center;

    @media (max-width: 580px) {
        border-top-left-radius: 0;
        padding: 0rem;
        margin: 0;
    }
}



.container-sum {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    align-items: stretch; /* Para expandirse verticalmente */
    height: 40px;
    margin-inline: 30px;
    font-size: 1.3rem;
  }

.table-box-container-topborderless {
    background-color: black;
    flex-grow: 1;
    border-radius: 6px;
    text-align: center;
    overflow-y: auto;
    display: flex;
    padding: 1rem;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    justify-content: center;
    padding-top: 0px;

    @media (max-width: 580px) {
        border-top-left-radius: 0;
        padding: 0rem;
        margin: 0;
    }
}

.table-box-top-hidden {
    padding-top: 0;
}

.table-box {
    display: flex;
    background-color: white;
    flex-grow: 1;
    text-align: center;
    overflow-y: auto;
    flex-direction: column;
}

/* Table scroll */
.table-box::-webkit-scrollbar {
    width: 8px;
}

.table-box::-webkit-scrollbar-track {
    background: whitesmoke;
    border-radius: 8px;
}

.table-box::-webkit-scrollbar-thumb {
    background: gray;
    border-radius: 8px;
}

.table-box::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.inventory-container .table {
    width: 100%;
    height: 100%;
    border-collapse: separate;
    border-spacing: 0rem;
}

.inventory-container tbody {
    padding-top: 1rem;
}

.inventary-tbody {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    padding-bottom: 0;
    padding-top: 0;
    background-color: white;

    @media (max-width: 1000px) {
        padding: .4rem;
    }
}

.inventory-department-scroll .Dropdown-control {
    width: auto;
}

.inventory-container thead {
    position: sticky;
    top: 0rem;
    z-index: 1;
}

.table-box tr td:hover {
    opacity: 80%;
    cursor: pointer;
}


.container {
    height: 100%;
    width: 100%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
}

.inventory-header {
    flex: 1;
    color: white;
    background-color: black;
    flex-grow: 1;
    font-size: 1.4rem;
    white-space: nowrap;
    font-weight: normal;
    text-align: center;
    padding-block: 8px;
    z-index: 10;
    /* padding-right: 20px; */
}

.inventory-header2 {
    flex: 1;
    color: white;
    background-color: black;
    flex-grow: 1;
    font-size: 1.4rem;
    white-space: nowrap;
    font-weight: normal;
    text-align: center;
    padding-block: 8px;
    /* padding-right: 20px; */
}
.display-none-phone {
    display: table-cell; 
    @media screen and (max-width:580px){
        display: none;
    }
}
.inventory-header3 {
    flex: 0.3;
    color: white;
    background-color: black;
    flex-grow: 0.45;
    font-size: 1.4rem;

    white-space: nowrap;
    font-weight: normal;
    text-align: center;
    padding-block: 8px;
    
}

@media screen and (max-width: 1200px) {
    .inventory-header {
        font-size: 1.1rem;
        padding-block: 6px;
    }
    .inventory-header2 {
        font-size: 1.1rem;
        padding-block: 6px;
    }
    .inventory-header3 {
        font-size: 1.1rem;
        padding-block: 6px;
    }
}

.inventory-container .table-header {
    border-radius: 0;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 1rem;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;

    @media (max-width: 1000px) {
        padding: 0;
    }
}

.table-header td {
    height: 100%;
}

.table-header tr {
    height: 100%;
    padding-inline: 1rem;
}


.product-row {
    padding-inline: 15px;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    font-family: 'Readex';
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF4E2;
    border-radius: 10px;

    @media (max-width: 1000px) {
        font-size: .7rem;
    }
}

.product-row-mobile {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem;
    background-color: #FFF4E2;
    border-radius: 10px;
    border-bottom: 6px solid white;
}

th,
td {
    text-align: center;
    vertical-align: middle;
}

.product-row td {
    font-family: 'Readex';
    background-color: #FFF4E2;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 7vh;
    padding-block: 2px;
    flex: 1;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-row div {
    flex: 1;
    /* white-space: nowrap; */
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}

.product-row-mobile td {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    height: 5vh;
    line-height: 5vh;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-image {
    max-height: 5vh;
}

.totals-container {
    display: flex;

    justify-content: space-between;
    background-color: #000;
    color: #fff;
    padding: 10px 1rem;
    margin-inline: 1rem;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
    @media (max-width: 580px) {
        display: none;
    }
  }
  
  .totals-item {
    flex: 1;
    text-align: center;
    font-weight: normal;
    font-size: 1.3rem;
  }
  
  
  
  

.inventory-row-bottom {
    background-color: black;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    align-items: center;
}

.inventory-row-bottom2 {
    background-color: black;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    align-items: center;
}

.add-product-label {
    overflow: hidden;
}

.add-product-button {
    font-family: 'bebas';
    min-height: 32px;
    width: 16%;
    min-width: 200px;
    font-size: 1.25rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    height: 100%;
    gap: 0.5rem;
    background: -webkit-linear-gradient(-60deg, #ffd700, #fea 45%, #ffffff 60%, #fea 25%, #ffd700);
}

.add-product-button:hover {
    background: -webkit-linear-gradient(-45deg, #ffd700, #fea 25%, #ffffff 38%, #ffd700 63%, #fea 87%, #ffd700);
}

.add-product-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px whitesmoke;
}

.inventory-search-bar-product {
    height: 100%;
    min-height: 40px;
    width: 30%;
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 6px;
}

.Dropdown-control .Dropdown-placeholder {
    display: flex;
    align-items: center;
}

.bottom-corner-buttons {
    width: auto;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
}

.search-icon {
    margin-left: 1rem;
}

.inventory-search-bar-product input[type='text'] {
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    border: none;
    font-family: 'readex';
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
}

.Dropdown-control {
    width: auto;
    height: 100%;
    padding: 6px;
    cursor: pointer;
    padding-left: 6px;
    display: flex;
    font-size: 1rem;
    font-weight: bold;
    font-family: 'readex';
    border-radius: 6px;
}

.dropdown-icon {
    margin-right: 6px;
    transform: translateY(3px);
}

.inventory-department-scroll {
    height: 100%;
    border-radius: 6px;
    width: 25%;
    white-space: nowrap;
}

.inventory-department-scroll .Dropdown-control {
    border-radius: 6px;
    padding: auto;
    display: flex;
}

.inventory-department-scroll .Dropdown-menu {
    border-radius: 6px;
    font-family: 'readex';
}

.inventory-department-scroll .Dropdown-option {
    border-radius: 6px;
}

.inventory-department-scroll {
    position: relative;
    z-index: 1;
    font-size: 90%;
}

.inventory-department-scroll .Dropdown-menu {
    position: absolute;
    top: auto;
    bottom: calc(100% + 4px);
    max-height: 200px;
    overflow-y: auto;
}

.inventory-department-scroll .Dropdown-menu {
    position: absolute;
    top: auto;
    bottom: calc(100% + 4px);
    max-height: 200px;
    overflow-y: auto;
}

@media screen and (max-width: 1200px) {
    .inventory-department-scroll {
        width: 30%;
    }

    .search-icon {
        display: none;
    }
}

@media screen and (max-width: 1000px) {
    .inventory-department-scroll {
        position: relative;
        z-index: 1;
        font-size: 90%;
        width: 100%;
    }
}
@media screen and (max-width: 1000px) {
    .sales-department-scroll {
        position: relative;
        z-index: 1;
        font-size: 90%;
        width: 100%;
    }
}

.sales-department-scroll {
    position: relative;
    z-index: 1;
    font-size: 90%;
    width: 100%;
}

.sales-department-scroll .Dropdown-menu{
    position: absolute;
    top: auto;
    bottom: calc(100% + 4px);
    max-height: 200px;
    overflow-y: auto;
    font-family: 'readex';
}

.grid-box {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 1rem;
    padding: 1rem 0;
    box-sizing: border-box;
}

.grid-container {
    display: grid;
    gap: 20px;
    /* Ajusta el número de columnas según el tamaño de la pantalla */
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
}

.grid-container p {
    text-overflow: ellipsis;
}

.grid-item {
    text-align: center;
    border-radius: 16px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;
}

.grid-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
    cursor: pointer;
}

.grid-item-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 80%;
}

.grid-item .grid-item-img-container img {
    max-width: 100%;
    max-height: 200px;
    height: auto;
}

.grid-item p {
    margin-top: 8px;
    font-size: 1.2rem;
}

.bottom-button {
    background-color: whitesmoke;
    color: whitesmoke;
    border: none;
    border-radius: 8px;
    height: 100%;
    min-height: 30px;
    padding: 0 0.75rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
}

.bottom-button:hover {
    opacity: 80%;
}

.inventory-title-button-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.inventory-title {
    margin: 0;
    color: whitesmoke;
}

.inventory-export-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4px;
    height: 100%;
    font-family: 'bebas';
    font-size: 1.15rem;
    color: black;
}

@media (max-width: 1000px) {

    .totals-item{
        display: none;
    }

    .container {
        width: 90%;
    }

    .bottom-button {
        min-height: 40px;
    }

    .inventory-export-button {
        padding-inline: 6px;
    }

    .inventory-row-bottom {
        display: flex;
        background-color: black;
        flex-direction: column;
        width: 100%;
        gap: 12px;
        margin: 0;
        margin-top: 1rem;
        align-items: flex-start;
    }

    .add-product-button {
        height: 100%;
        min-height: 40px;
        width: 100%;
    }

    .inventory-search-bar-product {
        width: 100%;
    }

    .grid-container {
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
        /* Adjust grid item width */
    }
}

@media screen and (max-width: 1000px) {
    .inventory-search-bar-product {
        width: 100%;
    }

    .inventory-row-bottom2 {
        flex-direction: column;
        width: 100%;
        gap: 4px;
    }

    .bottom-corner-buttons {
        width: 100%;
    }
}