.parent-envios {
    display: flex;
    height: 100%;
    width: 100%;
}

.deliveries-tab-container {
    background-color: #FFF4E2;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tab-gen-container {
    background-color: #FFF4E2;
    width: 80%;
    height: 750px;

    @media(max-width: 1500px) {
        width: 90%;
    }

    @media(max-width: 1000px) {
        width: 95%;
        height: 85%;
        margin-bottom: 30px;
    }
}

.delivery-nav-menu {
    display: flex;
    flex-direction: row;
    height: 7%;
}

.delivery-nav-menu ul {
    display: flex;
    list-style: none;
    width: 100%;
}

.delivery-nav-menu ul li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0% 1%;
    width: 10%;
    padding: 0% 9%;
    border-radius: 6px 6px 0px 0px;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
    letter-spacing: 1px;
    font-size: 1.3rem;
    background-color: #D9D9D9;
    font-family: "bebas";
    box-shadow: 3px 0px 4px rgba(0, 0, 0, 0.4), -4px -4px 8px rgba(0, 0, 0, 0.1);

    @media (max-width: 1200px) {
        font-size: 1rem;
        width: 25%;
    }

    @media (max-width: 900px) {
        font-size: 0.7rem;
    }
}

.delivery-nav-menu ul li:first-child {
    margin-left: 0px;
}

.deliveries-lower-container {
    width: 100%;
    height: 83%;
    background-color: white;
    padding: 1.6%;
    ;
    border-radius: 0px 9px 0px 0px;
    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4), -4px -4px 8px rgba(0, 0, 0, 0.1);
}

.tab-container {
    width: 100%;
    height: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    padding: .5rem;
}

.delivery-tab-footer {
    width: 100%;
    height: 10%;
    background-color: black;
    display: flex;
    align-items: center;
    gap: 16px;
    border-radius: 0px 0px 7px 7px;
    box-shadow: 4px 8px 4px rgba(0, 0, 0, 0.4), -4px -4px 8px rgba(0, 0, 0, 0.1);

    @media(max-width: 900px) {
        justify-content: space-around;
        gap: 0;
    }
}

.search-packages-container {
    margin-left: 12px;
    border-style: none;
    font-family: "bebas";
    color: black;
    border-radius: 7px;
    font-size: 1rem;
    width: 30%;
    height: 70%;
    display: flex;
    align-items: center;
    background-color: white;

    @media(max-width: 900px) {
        flex: 0.9;
        margin: 0;
    }
}

.search-packages {
    border-style: none;
    font-family: "bebas";
    width: 100%;
    height: 100%;
    border-radius: 7px;
    color: black;
    font-size: 1.3rem;
    outline: none;

    @media(max-width: 900px) {
        font-size: 1rem;
    }
}

.date-modal-picker {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 999;
}

.date-picker-container {
    border-radius: 1rem;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(2px);
}

.date-deploy-button,
.details-deploy-button {
    font-family: 'bebas';
    padding: 6px;
    width: 15%;
    min-width: 100px;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    height: 70%;
    gap: 0.4rem;
    
    @media(max-width: 900px) {
        min-width: none;
    }
}

.details-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(20, 20, 20, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(2px);
    z-index: 999;
}

.details-modal-container {
    padding: 2%;
    border-radius: 1.4rem;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: auto;
    z-index: 1000;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    transform: translate(-50%, -50%);
    backdrop-filter: blur(2px);
    min-width: 350px;
    background-color: white;

    @media(max-width: 1500px) {
        width: 30%;
    }
}

.calendar-close-button1 {
    border-radius: 6rem;
    position: absolute;
    top: 0px;
    right: 6px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details-close-button1 {
    border-radius: 6rem;
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: black;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

}

.input-area {
    width: 100%;
    height: 100px;
    padding: 10px;
    font-size: 16px;
    border: 2px solid #e8e7e7;
    margin-right: 10px;
    resize: vertical;
    border-radius: 10px;
    background-color: #e8e7e7;
}

.delivery-container-products {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 180px;
    padding: 10px;
    border: 2px solid #e8e7e7;
    border-radius: 10px;
    background-color: #e8e7e7;
    overflow-y: auto;
    gap: 10px;
}

.delivery-row-product {
    display: flex;
    flex-direction: row;
    cursor: pointer;
    background-color: #D9D9D9;
    border: 1px gray solid;
    border-radius: 0.5rem;
    padding: 6px;
    gap: 6px;
    align-items: center;
    
    &:hover {
        opacity: 92%;
    }
}

.delivery-column-products {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.delivery-list-img {
    height: 64px;
    max-width: 64px;
    border-radius: 0.5rem;
}

.delivery-crossed-text {
    color: #888;
    text-decoration: line-through;
    margin-right: 6px;
}

.input-texto {
    padding: 8px;
    font-size: 12px;
    border: 2px solid #e8e7e7;
    border-radius: 8px;
    background-color: #e8e7e7;
}

.detalles-dos {
    display: flex;
    flex-direction: column;
}

.detalles-dos-input {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* background-color: #4CAF50; */
    justify-content: space-between;
    width: 100%;
}

.descripcion-direccion {
    color: black;
    margin-top: 5px;
}

.titulo-direccion {
    text-align: left;
    color: black;
}

.detalles-tres-input {
    display: flex;
    flex-direction: row;
}

.detalles-tres-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
}

.modal-titulo {
    font-size: 1.3rem;
    text-align: center;
    font-weight: lighter;
}

.delivery-clean-filters {
    padding: 14px;
    border-radius: 6px;
    background-color: white;
    cursor: pointer;
    border: none;
}

.full-width {
    width: 100%;
    margin: 0;
}

@media (max-width: 1100px) {
    .date-deploy-button {
        font-size: 1rem;
    }

    .delivery-clean-filters {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .delivery-clean-filters {
        padding: 8px;
    }
}