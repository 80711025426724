@font-face {
  font-family: 'Readex';
  src: url('./assets/fonts/ReadexPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'bebas';
  src: url('./assets/fonts/BebasNeue-Regular.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}