.refund-parent-container {
    width: 100%;
    height: 100%;
    display: flex;
    background-color: #FFF4E2;
    justify-content: space-between;
}

.refund-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    margin-inline: 1rem;
}

.refund-center {
    width: 60%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.refund-card {
    width: calc(52% - 2rem);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 9px;
    background-color: #000;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 90%;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
}

@media screen and (max-width: 1600px) {
    .refund-center {
        width: 90%;
    }
 }

 @media screen and (max-width: 900px) {
    .refund-parent-container {
        flex-direction: column;
    }
 }

.refund-card-content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.refund-card h1 {
    color: whitesmoke;
    margin: 0;
    margin-bottom: 1rem;
    font-size: 1.3rem;
    font-family: "Readex";
}

.refund-list-container {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 1rem;
    align-items: center;
    flex-direction: column;
    background-color: whitesmoke;
    overflow-y: auto;
}

.refund-list-container-text {
    font-size: 1.1rem;
    color: #444;
    text-align: center;
    font-family: "Readex";
}

.discount-text {
    font-size: 0.8em;
    color: gray;
    margin-left: 4px;
}

.refund-total-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 0rem 1rem;
}

.refund-total-text {
    margin: 0;
    margin-top: 1rem;
    font-size: 2.5rem;
    color: #fff;
    text-align: right;
    font-weight: normal;
}

.refund-advice {
    width: calc(100% - 2rem);
    padding: 1rem;
    text-align: center;
    border-radius: 9px;
    background-color: white;
}

.refund-advice-text {
    margin: 0;
    font-size: 1.3rem;
    color: #000;
    font-family: "Readex";
}

.refound-not-found-advice {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    color: #F44336;
    text-align: center;
    height: 100%;
}

.refund-text-disabled {
    color: #676464;
}

.refund-dot-disabled {
    border-bottom: 4px dotted #676464;
}

.refund-search-bar-product-container {
    width: calc(90% - 12px);
    background-color: #000;
    padding: 6px;
    border-radius: 20px;
}

.refund-search-bar-product {
    min-height: 32px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 15px;
}

.refund-search-bar-product input[type='text'] {
    flex: 1;
    padding: 8px;
    margin-left: 4px;
    font-family: 'readex';
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    box-sizing: border-box;
}

.refund-card-right {
    padding-top: 2rem;
}

.refund-card .register__input-box {
    justify-content: center;
}

.refund-card .register__text {
    font-weight: normal;
    font-size: 2rem;
}

.refund-card .total__input {
    font-size: 1.5rem;
}

.refund-card .customer-row__icon {
    width: auto;
    height: 38px;
    min-width: 26px;
    min-height: 26px;
    font-size: 26px;
    margin-right: 10px;
}

.refund-card .button--save {
    width: 180px;
}

.logo-container img {
    width: 10rem;
    height: 5rem;
}

.card-options {
    height: 25%;
    width: 80%;
    display: flex;
    flex-direction: column;
}

.register-total {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.card-buttons {
    height: 50%;
    width: 100%;
}

.button-opt.refund {
    font-family: 'bebas';
    width: 50%;
    height: 60%;
    border-style: none;
    border-radius: 9px;
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
    font-size: 1.8rem;
    cursor: pointer;
}

@media (max-width: 1024px) {
    .button-opt.refund {
        font-size: 1.3rem;
    }
}

.button-opt.refund:hover {
    opacity: 90%;
}

.refund-user-info {
    gap: 10px;
    display: flex;
    justify-content: center;
}

.refund-user-info input {
    font-family: 'readex';
    font-size: 1rem;
    text-align: center;
    border: none;
    outline: none;
    background-color: transparent;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
}

.refound-user-field {
    width: 100%;
    background-color: white;
    border: 2px solid #676464;
    border-radius: 20px;
    padding: 0%;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 1rem;
    padding-right: 1rem;
}

.refound-user-field-warn {
    border-color:goldenrod;
    border-width: 3px;
}

.refound-user-field-correct {
    border-color: green;
    border-width: 3px;
}

.refund-user-button {
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    cursor: pointer;
    border: none;
}

.refund-user-button:hover {
    opacity: 80%;
}

.refund-user-column {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 10px;
}

.refund-input-title {
    color: white;
    font-size: 1.4rem;
}

.refund-points {
    background-color: white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30%;
    font-size: 1.4rem;
    gap: 2px;
}

.refund-points-subtitle {
    font-size: 0.8rem;
}

.refund-right-p {
    text-align: center;
}