.parent {
   background-color: #FFF4E2;
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   height: 100%;
}

.users-container {
   display: flex;
   flex-direction: column;
   height: calc(100% - 5rem);
   width: 100%;
   padding: 1rem;
   overflow: hidden;
   justify-content: center;
}

.containerUsers {
   width: 100%;
   background-color: #000;
   border-radius: 16px;
   display: flex;
   flex-direction: column;
   padding: 20px;
}

.users-table-box {
   background-color: white;
   flex-grow: 1;
   border-radius: 16px;
   text-align: center;
   overflow-y: auto;
   display: flex;
   /* margin: 1rem 0; */
   margin-top: 1rem;
   padding: 0 1rem;
   justify-content: center;
   overflow-x: hidden;
}

.users-table {
   border-collapse: separate;
   border-spacing: 0 1rem;
   width: 100%;
   height: 100%;
   border-radius: 16px;
   background-color: white;
   text-align: center;
   margin: 0;
   justify-content: center;
}

.users-container th {
   padding: 10px;
   text-align: center;
   vertical-align: middle;
}

.users-container td {
   background-color: #FFF4E2;
   padding: 10px;
   text-align: center;
   vertical-align: middle;
}

/* Inicio de renglones redondos */
.parent .users-container th td:first-child,
td:first-child {
   border-top-left-radius: 9px;
   border-bottom-left-radius: 9px;
}

.parent .users-container th td:last-child,
td:last-child {
   border-top-right-radius: 9px;
   border-bottom-right-radius: 9px;
}

.parent .users-container tr td:first-child th:first-child {
   border-top-left-radius: 9px;
}

.parent .users-container tr td:first-child th:last-child {
   border-top-right-radius: 9px;
}

.users-containertr:last-child td:first-child {
   border-bottom-left-radius: 9px;
}

.users-container tr:last-child td:last-child {
   border-bottom-right-radius: 9px;
}

/* Fin de renglones redondos */
/* Tutorial: https://shihabiiuc.com/table-rounded-css/ */
.table__options {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: space-around;
   /*Positioning stuff*/
   /*Box model stuff*/
   border-radius: 15px;
   padding: 1rem;
   gap: 1rem;

   height: 80%;
}

.option-button {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: center;
   /*Box model stuff*/
   width: 35px;
   height: 35px;
   border: none;
   border-radius: 32px;
   background-color: black;
   /*Manipulations stuff*/
   transition: transform .7s ease-in-out;
   /*Miscellaneous*/
   cursor: pointer;
}

.option-button-delete {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: center;
   /*Box model stuff*/
   width: 35px;
   height: 35px;
   border: none;
   border-radius: 32px;
   background-color: crimson;
   /*Manipulations stuff*/
   transition: transform .7s ease-in-out;
   /*Miscellaneous*/
   cursor: pointer;
}

.option-button--hover-shadow:hover {
   border-radius: 20px;
   box-shadow: 0px 1px 1px 0.5px;
   opacity: 0.8;
}

.option__button--edit {
   height: 30px;
   width: 30px;
}

.option__button--delete {
   height: 30px;
   width: 30px;
}

.option__button--hover-border:active {
   border-radius: 20px;
   box-shadow: 0px 2px 1px 1px;
}

.usersHeader {
   /* width: 16.6666%; */
   background-color: #000;
   color: white;
   font-weight: 200;
}

.header-row {
   display: flex;
   flex-direction: row;
   height: 100%;
   justify-content: center;
   align-items: center;
}

.user-row {
   display: flex;
   flex-direction: row;
   width: calc(100% - 2rem);
   justify-content: space-evenly;
   align-items: center;
   margin-bottom: 1rem;
   margin-inline: 1rem;
   background-color: #FFF4E2;
   border-radius: 10px;
}

.user-row td {
   text-align: center;
   display: flex;
   align-content: center;
   justify-content: center;
   height: 6vh;
   line-height: 4vh;
   width: 20%;
   font-family: "readex";
}

.user-row> :last-child {
   display: flex;
   align-items: center;
}

.users-bottom-row {
   background-color: black;
   display: flex;
   flex-direction: row;
   width: 100%;
   gap: 1rem;
   margin-top: 1rem;
   padding: 1rem;
   align-items: center;
}

.add-user-button {
   font-family: 'bebas';
   min-height: 32px;
   width: 14%;
   min-width: 200px;
   font-size: 1.4rem;
   display: flex;
   flex-direction: row;
   justify-content: center;
   align-items: center;
   color: black;
   border: none;
   border-radius: 6px;
   cursor: pointer;
   height: 100%;
   gap: 0.5rem;
   background: -webkit-linear-gradient(-60deg, #ffd700, #fea 45%, #ffffff 60%, #fea 25%, #ffd700);
}

.add-product-button:hover {
   background-color: #e0e0e0;
}

.add-product-button:focus {
   outline: none;
   box-shadow: 0 0 0 3px #e0e0e0;
}

.search-bar-users {
   width: 30%;
   display: flex;
   align-items: center;
   background-color: whitesmoke;
   border-radius: 9px;
   height: 100%;

}

.search-bar-users input[type="text"] {
   flex: 1;
   padding: 8px;
   margin-left: 4px;
   border: none;
   background-color: transparent;
   font-size: 1rem;
   outline: none;
   font-family: 'Readex';
}

.category-scroll .Dropdown-control {
   width: calc(100% +50px);
   height: 100%;
}


.category-scroll .Dropdown-control {
   border-radius: 9px;
   height: 100%;
   display: flex;
}

.category-scroll .Dropdown-menu {
   border-radius: 9px;
}

.category-scroll .Dropdown-option {
   border-radius: 9px;
}

.category-scroll .Dropdown-root {
   position: relative;
   z-index: 1;
}

.category-scroll .Dropdown-menu {
   position: absolute;
   top: auto;
   bottom: calc(100% + 4px);
   /* Ajusta el valor para dejar espacio entre el Dropdown y el control */
   max-height: 200px;
   /* Ajusta el valor según tus necesidades */
   overflow-y: auto;
}

.view-mode-button {
   background-color: whitesmoke;
   color: whitesmoke;
   border: none;
   border-radius: 8px;
   padding: 8px 12px;
   font-weight: bold;
   cursor: pointer;
   transition: background-color 0.3s ease-in-out;
   /* margin: 0; */
   /* float: right; */
}

.both-cont {
   width: 100%;
   height: 100%;
   display: flex;
   justify-content: space-between;
   gap: .2rem;
}

.view-mode-button:hover {
   opacity: 80%;
}

.table-padding-bottom {
   padding-bottom: 1rem;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
}

@media screen and (max-width: 800px) {
   .users-bottom-row {
      flex-direction: column;
      gap: .3rem;
   }

   .add-user-button {
      width: 100%;
   }

   .search-bar-users {
      width: 100%;
   }

   .category-scroll {
      width: 100%;
   }

   th {
      padding: 5px;


   }

   @media screen and (max-width: 520px) {
      th {
         padding: 2px;
      }
   }

   .user-row td {
      font-size: 0.8rem;
   }

   .user-row2 {
      display: none !important;
   }
   .inventory-header2{
      display: none !important;
   }
}

/* @media screen and (max-width: ) */