.add-list-father-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    background-color: #FFF4E2;
}

.add-list-container {
    display: flex;
    justify-content: center;
    align-self: center;
    width: 100%;
}

.add-list-header {
    color: black;
    font-size: 2rem;
    font-weight: normal;
    text-align: left;
    margin: 0;
    margin-bottom: 1rem;
}

.list-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.product-card {
    background-color: #FFF4E2;
    border-radius: 6px;
    padding: 1rem;
    width: 50%;
}

.drop-row-add-list {
    font-weight: normal;
    display: flex;
    flex-direction: row;
    width: 100%;
    font-family: "readex";
    justify-content: space-between;
    padding-inline: 10px;
    
}

.add-list-input {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 1rem;
    font-family: "readex";
    width: calc(100% - 100px);

}

.add-list-input input[type="text"] {
    flex: 1 1;
    padding: 8px;
    margin-left: 4px;
    width: calc(100% - 100px);
    border: none;
    background-color: transparent;
    font-size: 1rem;
    outline: none;
    font-family: "readex";
}

.add-list-column {
    max-height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    padding-bottom: 10px;
    overflow-y: auto;
    width: 100%;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    padding-inline: 10px;
}

.add-list-button {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 40px;
    background-color: whitesmoke;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.add-list-back-button {
    width: 100%;
    font-size: 1.2rem;
    color: whitesmoke;
    padding: 5px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    background-color: black;
    border-radius: 10px;
    border: none;
    cursor: pointer;
}

.add-list-column::-webkit-scrollbar {
    width: 4px;
}

.add-list-column::-webkit-scrollbar-track {
    background: transparent;
}

.add-list-column::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.add-list-column:hover::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
    .add-list-column .drop-row .add-product-input {
        width: 70%;
    }

    .add-list-column .add-list-button {
        width: 10%;
    }

    .product-card {
        width: 90%;
    }
}