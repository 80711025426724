.test {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF4E2;
}

.th .table-header-exit {
    width: 15%;
    background-color: #000;
    color: #fff;
    flex-grow: 1;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
}

.contenedor {
    width: 88%;
    height: 85%;
    background-color: antiquewhite;
    margin: auto;
    border-radius: 9px;
}

.opciones {
    background-color: #FFF4E2;
    /* background-color: aqua; */
    height: 7%;
    display: flex;
    align-items: flex-end;
}

.inventory-exits-clean-margin {
    margin-top: 0;
    padding-top: 0;
}

.pages-button {
    width: 18%;
    height: 85%;
    border: none;
    margin-right: 1%;
    border-radius: 8px 8px 0px 0px;
    background-color: #000;
    color: #FFF;
    font-size: 1.3em;
}

.cuadro {
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;

}

.lista-articulos {
    width: 97%;
    height: 95%;
    background-color: #FFF;
    border: 4px solid #000;
    border-radius: 8px;

}

.item-property {
    width: 20%;
    height: 10%;
    display: inline-block;
    text-align: center;
    background-color: #000;
    color: white;
    padding: 15px;

}

/* Estilo para alternar color de fondo de los grupos */
.inventary-row-color {
    background-color: #e9ecef;
}

.exits-bill-id-header {
    width: 100%;
    text-align: left;
    margin-top: 5px;
    font-size: 1.125rem;
}

.exits-item-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    padding: 5px 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

.exits-item {
    flex: 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 6px;
}

.filtro {
    height: 10%;
    background-color: black;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 0 0 10px 10px;
}

#search-div {
    border-radius: 9px;
    width: 35%;
    margin-right: 2%;
}

.inventary-exits-total {
    min-width: 25%;
    font-size: 1.8rem;
    color: white;
}

.inventary-row-color {
    background-color: silver !important;
}

.debt-row-color {
    background-color: #FFA8A8 !important;
}

.inventory-department-scroll .Dropdown-control {
    width: calc(100%)
}



.container-sum>p {
    display: flex;
    align-items: center;
    /* Centra el contenido verticalmente */
    justify-content: center;
    /* Centra el contenido horizontalmente */
    margin: 0;
    /* Remueve el margen por defecto de <p> */
}

.exits-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0rem;
}

.inventory-container .table-header-exit {
    position: sticky;
    top: 0rem;
    z-index: 1;
}

.table-box-top-hidden {
    padding-top: 0;
}

.exits-row {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid #ccc;
    white-space: nowrap;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'readex';
    font-size: 0.9rem;
    background-color: #FFF4E2;
    border-radius: 10px;
    width: 100%;
    cursor: pointer;

    &:hover {
        transform: translateY(-2px);
    }
}

.exits-totals-container {
    display: flex;

    justify-content: space-between;
    background-color: #000;
    color: #fff;
    padding: 10px 1rem;
    margin-inline: 1rem;
    border-bottom-left-radius: 9px;
    border-bottom-right-radius: 9px;
}

.exits-totals-item {
    flex: 1;
    text-align: center;
    font-weight: normal;
    font-size: 1.3rem;
}

@media screen and (max-width: 580px) {
    .exits-totals-container {
        margin: 0;
        width: 100%;
    }
}

@media screen and (max-width: 1200px) {
    .inventary-exits-total {
        font-size: 1.4rem;
    }
}