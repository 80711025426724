.parentSales {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #FFF4E2;
}

.sales-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90%;
    width: 100%;
    overflow: hidden;
    margin-inline: 2rem;
    z-index: 1;
}

.receipt-container {
    display: flex;
    width: 35%;
}

.cart-receipt {
    display: flex;
    gap: 2rem;
    width: 98%;
    height: 97%;
}

.receipt-register {
    display: flex;
    gap: 1rem;
    width: 90%;
    height: 100%;
    padding: 0 0 1rem 0;
}

.register-container {
    height: 100%;
    width: 50%;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
    border-radius: 9px;
}

.receipt-container-checkout {
    height: 100%;
    width: 50%;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
    border-radius: 9px;
}

.sales-dot-separator {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    padding-left: 4px;
    padding-right: 4px;
    overflow: hidden;
    white-space: nowrap;
}

.sales-no-stock {
    color: red;
}

.sales-product-focus {
    border: 3px solid goldenrod !important;
}

.product-list-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 65%;
    border-radius: 25px;
    background-color: #000;
    text-align: center;
}

.sales-grid-box {
    flex-grow: 1;
    display: grid;
    height: 80%;
    padding: 1rem;
    border-radius: 25px;
    background-color: white;
    gap: 20px;
    overflow-y: auto;
    border-radius: 0%;
}

.sales-grid-box::-webkit-scrollbar {
    width: 6px;
    border-radius: 5px;
}

.sales-grid-box::-webkit-scrollbar-track {
    background: transparent;
}

.sales-grid-box::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
}

.sales-grid-box::-webkit-scrollbar-button {
    display: none;
}

.sales-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: repeat(2, 1fr);
    justify-content: center;
    gap: 20px;
}

.sales-grid-container p {
    font-family: 'readex';
}

.sales-grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 12px;
    border: 1px solid #ccc;
    border-radius: 16px;
    background-color: whitesmoke;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.sales-grid-item.gray-bg {
    background-color: lightgray;
}

.sales-grid-item:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transform: translateY(-2px);
}

.sales-grid-item-img-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 80%;
    justify-content: center;
}

.sales-grid-item .sales-grid-item-img-container img {
    height: auto;
    max-height: 200px;
    max-width: 100%;
}

.sales-grid-item p {
    font-size: 1.2rem;
    margin-top: 8px;
}

.search {
    display: flex;
    align-items: center;
    width: 45%;
    margin: 0;
    height: 52px;
    border-radius: 15px;
    background-color: whitesmoke;

    @media screen and (max-width: 1200px) {
        width: auto;
        
    }
}

.search__icon {
    margin: 0 1rem;
}

.search__input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14pt;
}

.product-list-container {
    border-radius: 9px;
}

.register-button .button-text {
    font-weight: bold;
    font-size: 20px;
    color: #000;
    text-align: center;
    margin-inline: 3%;
}

.search,
.register {
    font-family: Arial, Helvetica, sans-serif;
    color: rgb(12, 13, 13);
    font-weight: bold;
}

.register {
    width: 25%;
    margin: 1rem 0;
    height: 40px;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
    border: none;
    border-radius: 15px;
    background-color: white;
    font-size: 16pt;
    font-weight: bold;
    color: rgb(0, 0, 0);
    cursor: pointer;
    gap: 1rem;
    box-sizing: border-box;
}

.sales-button-print-last {
    width: auto;
    font-family: 'bebas';
    padding-inline: 10px;
    font-size: 1.6rem;
    height: 52px;

    &:hover {
        opacity: 90%;
    }
}

@media  (min-width: 1040px) {
    .sales-button-print-last {
        width: auto;
        font-size: 1.6rem;
    }    
}

.register__input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    font-size: 14pt;
    font-weight: bold;
}

.search-register-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1rem;
    gap: 1rem;
}

@media (max-width: 1200px){
    .search-register-container{
        padding: 1rem 1rem;
    }
}



.search-register-container>* {
    border-radius: 9px;
}

.register-button {
    border-style: none;
    font-size: 14pt;
    padding: 0% 5%;

}

.overlay-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    /* Fondo oscuro con transparencia */
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(5px);
    z-index: 999;
}

.custom-ui-modal {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    /* Ancho del modal, ajusta según tus necesidades */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    text-align: center;
}

.custom-ui-modal h2 {
    margin: 0;
}

.custom-ui-modal input {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
    box-sizing: border-box;
    font-size: 1rem;
}

.custom-ui-buttons-modal {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.custom-ui-buttons-modal button {
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    transition: opacity 0.3s ease-in-out;
}

.custom-ui-buttons-modal button:hover {
    opacity: 0.8;
}

.custom-ui-buttons-modal button.cancel {
    background-color: #ccc;
}

.custom-ui-buttons-modal button.confirm {
    background-color: #008000;
    color: #fff;
}

.confirm-modal {
    background-color: #008000;
    color: whitesmoke;
}

.cancel-modal {
    background-color: #9AA;
    color: whitesmoke;
}

@media screen and (max-width: 1500px) {
    .product-list-container {
        width: 60%;
    }

    .sales-container {
        margin-inline: 1rem;
    }

    .receipt-container {
        width: 40%;
    }
}

@media screen and (max-width: 800px) {
    .receipt-register {
        width: 90%;
    }

}

@media screen and (max-width: 1050px) {
    .receipt-register {
        width: 90%;
    }

    .product-list-container {
        width: 55%;
    }

    .receipt-container {

        width: 45%;
    }

}

@media screen and (max-width: 900px) {
    .sales-container {
        align-items: flex-start;
        margin-bottom: 2rem;
    }

    .receipt-register {
        height: 90%;
    }
}

/* Estilos para el botón de registrar retiro */
.charge--hover-shadow.reduce-golden {
    margin-left: 10px;
}

/* Estilos para el modal de retiro de efectivo */
.custom-ui-modal {
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
}

.custom-ui-buttons-modal {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.cancel-modal,
.confirm-modal {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.withdrawals-cancel-modal {
    font-family: 'bebas';
    min-height: 32px;
    margin-top: 10px;
    min-width: 100px;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    gap: 0.5rem;
    background: gray;
    color: #fff;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);

    &:hover {
        background-color: #888;
    }
}

.withdrawals-confirm-modal {
    margin-top: 10px;
    font-size: 1.4rem;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
}

.withdrawals-table {
    margin-top: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 300px;
    overflow: auto;
}

.withdrawals-table::-webkit-scrollbar {
    width: 5px;
  }
  
  .withdrawals-table::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  .withdrawals-table::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 5px;
  }
  
  .withdrawals-table::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

.withdrawals-table h2 {
    background-color: #f2f2f2;
    padding: 10px;
    margin: 0;
}

.withdrawals-table__header {
    display: flex;
    background-color: #e0e0e0;
    padding-block: 8px;
    font-weight: bold;
    font-size: 1.4rem;
}

.withdrawals-table__header-item {
    flex: 1;
    margin: 0;
}

.withdrawals-table__header-item:first-child {
    flex: 0.5;
}

.withdrawals-table__content {
    display: flex;
    flex-direction: column;
}

.withdrawals-table__row {
    display: flex;
    align-items: center;
    border-top: 1px solid #ccc;
    min-height: 32px;
}

.withdrawals-table__item {
    flex: 1;
    justify-content: center;
    align-items: center;
    font-family: 'readex';
}

.withdrawals-table__item:first-child {
    flex: 0.5;
}

.toggle-view-button {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.withdrawals-history-modal {
    margin-top: 10px;
    background-color: white;
    font-family: 'bebas';
    padding: 10px 20px;
    min-height: 32px;
    min-width: 100px;
    font-size: 1.4rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    gap: 0.5rem;
    background: black;
    color: #fff;
    font-size: 1.4rem;
    box-shadow: 5px 5px 6px rgba(12, 12, 12, 0.6);
    
    &:hover {
        opacity: 80%;
    }
}

.sales-button-left-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}