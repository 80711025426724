.utilities-parent {
   background-color: #FFF4E2;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: stretch;
   width: 100%;
   height: 100%;
}

.utilities-container {
   display: flex;
   flex-direction: column;
   /*background-color: #098d48;*/
   border-radius: 25px;
   width: calc(100% - 2rem);
   height: calc(100% - 2rem);
   margin: 1rem;
}

.utilities-box {
   width: 100%;
   height: 100%;
   background-color: black;
   overflow-x: hidden;
   overflow-y: auto;
   scrollbar-width: thin;
   border-radius: 25px;
}

.utilities-grid-container {
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
   gap: 20px;
   padding: 20px;
}

.utility-header {
   position: relative;
   display: flex;
   justify-content: center;
   align-items: center;
   gap: 30px;
   width: 100%;
}

.utility-header .Dropdown-control {
   width: calc(100% + 60px);
   height: 100%;
   padding: 6px;
   cursor: pointer;
   padding-left: 6px;
   display: flex;
   font-size: 1rem;
   font-weight: bold;
   font-family: 'readex';
   border-radius: 6px;
}

.utilities-export-button {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: black;
   color: white;
   position: absolute;
   top: 0px;
   right: 16px;
   background-color: black;
   color: white;
   padding: 4px 10px;
   border: none;
   border-radius: 4px;
}

.utilities-export-button:hover {
   background-color: #222;
   cursor: pointer;
}

.utility-scroll {
   font-size: 90%;
   position: relative;
   height: 100%;
   border-radius: 6px;
   white-space: nowrap;
}

.utility-scroll .Dropdown-control {
   border-radius: 6px;
   display: flex;
}

.utility-scroll .Dropdown-menu {
   border-radius: 6px;
   font-family: 'readex';
}

.utility-scroll .Dropdown-option {
   border-radius: 6px;
}

.utility-scroll .Dropdown-menu {
   position: absolute;
   top: auto;
   max-height: 200px;
   overflow-y: auto;
}

.utility-year-selector {
   display: flex;
   align-items: center;
   gap: 10px;
}

.utilities-export-button {
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: black;
   color: white;
   position: absolute;
   top: 0;
   right: 16px;
   padding: 4px 10px;
   border: none;
   border-radius: 4px;
}

.utilities-export-button:hover {
   background-color: #222;
   cursor: pointer;
}

.utilities-export-button-mobile {
   display: flex;
   justify-content: center;
   align-items: center;
   padding-block: 4px;
   font-size: 1.2rem;
   position: static;
   gap: 10px;
   width: 100%;
   margin-top: 6px;
   background-color: gray;
   border-radius: 8px;
   color: white;
   text-align: center;
   -webkit-user-select: none;
   -ms-user-select: none;
   user-select: none;
   cursor: pointer;
}

.utilities-export-button-mobile:hover {
   background-color: silver;
}

.chart-content-father {
   display: flex;
   justify-content: center;
   width: 100%;
}

.chart-container {
   width: 100%;
   max-width: 580px;
   height: 300px;
}

.utility-payment-content .css-ry2sa1 {
   fill: white;
}

@media (max-width: 550px) {
   .utilities-grid-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
      padding: 20px;
   }

   .utility-stock-total {
      padding-left: 50% !important;
   }

   .utility-year-selector {
      display: none;
   }

   .utility-month-header {
      gap: 20px;
   }

   .utilities-box {
      margin-bottom: 50px;
   }

   .utilities-export-button {
      display: none;
   }

   .utility-scroll .Dropdown-control {
      font-size: 0.8rem;
   }

   .utility-scroll .Dropdown-menu {
      font-size: 0.8rem;
      width: auto;
   }
}

@media (max-width: 900px) {
   .utilities-box {
      margin-bottom: 50px;
   }

   .chart-container {
      max-width: 100%;
   }
}