.receipt-container {
   border-radius: 9px;
}

.ticket-card {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100%;
   width: 100%;
   border-radius: 9px;
   background-color: #000000;
   padding: 0rem;
   box-sizing: border-box;
   margin-bottom: 15px;
}

.header {
   display: flex;
   flex-direction: column;
}

.sales-title {
   display: flex;
   justify-content: center;
   align-items: center;
   color: white;
   font-size: 2rem;
   font-weight: normal;
   padding: 0.5rem;
   margin: 0;
   width: 100%;
   height: 9%;
}

.products-list {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   background-color: rgb(255, 255, 255);
   flex-grow: 1;
   width: 100%;
   height: 80%;
}

.button-container {
   display: flex;
   justify-content: center;
   height: 5%;
   padding: 1rem 0 0 0;
   width: 100%;
   border-radius: 20px;
}

.sales-content {
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 100%;
   width: 100%;
}

.pay-box {
   position: relative;
   display: flex;
   flex-direction: column;
   align-items: center;
   height: 74%;
   margin-top: 1rem;
   margin-bottom: .3rem;
   max-height: 80%;

}

.sales-date {
   display: inline-block;
   margin: 5px 0px;
   font-size: 20px;
   color: black;
   font-weight: normal;
   text-align: center;
}

.products-box {
   display: flex;
   flex-direction: column;
   width: 100%;
   height: 100%;
   overflow: auto;
   scrollbar-color: gray;
   margin-bottom: 15px;
}

.products-box-checkout {
   display: flex;
   flex-direction: column;
   box-sizing: border-box;
   width: 100%;
   padding: 0 1rem;
   height: 100%;
   overflow: auto;
   scrollbar-color: gray;
}

.products-box::-webkit-scrollbar {
   border-radius: 4px;
   margin-left: 5px;
   padding: 5px;
   width: 8px;
}

.products-box::-webkit-scrollbar-thumb {
   background-color: #000000;
   border-radius: 4px;
}

.products-box::-webkit-scrollbar-thumb:hover {
   background-color: #555555;
}

.products-box::-webkit-scrollbar-track {
   background-color: whitesmoke;
   border-radius: 4px;
   margin-left: 5px;
}

.sales-product-column {
   display: flex;
   flex-direction: column;
   margin-top: 10px;
   margin-bottom: 10px;
   width: 100%;
}

.sales-product-row {
   display: flex;
   justify-content: space-between;
   padding-left: 20px;
   padding-right: 10px;
   width: calc(100% - 20px);
   font-weight: 600;
}

.sales-dot-separator {
   border-bottom: 4px dotted #000;
   line-height: 10px;
   min-height: 1px;
   overflow: hidden;
   margin-bottom: 7px;
   margin-inline: 5px;
}

.juguete {
   font-family: 'readex';
   font-size: 1.1rem;
   margin-bottom: 5px;
   margin-top: 5px;
}

@media screen and (max-width: 1400px) {
   .juguete {
      font-size: 1rem;
   }
}

@media screen and (max-width: 1300px) {
   .juguete {
      font-size: 1rem;
   }
}

@media screen and (max-width: 1200px) {
   .juguete {
      font-size: 1rem;
   }
}

@media screen and (max-width: 620px) {
   .juguete {
      font-size: 0.6rem;
   }
}

.sales-button-row {
   display: flex;
   gap: 10px;
}

.sale-pad-button {
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 5px 5px;
   border: none;
   border-radius: 20px;
   background-color: white;
   font-weight: bold;
   transition: background-color 0.5s ease-in-out;
   box-shadow: 2px 2px 4px #888;
   cursor: pointer;

   &:hover {
      transform: scale(1.2);
      background-color: #ddd;
   }
}

.sales-pad-button-offer {
   padding: 5px 7px;
   box-shadow: 2px 2px 4px #666;
}

.recipent-header-row {
   display: flex;
   flex-direction: row;
   margin-top: 1rem;
   gap: 10px;
}

.recipent-cancel2x1 {
   font-family: 'readex';
   background-color: white;
   border: none;
   cursor: pointer;
   border-radius: 8px;
   padding: 2px;
   padding-inline: 6px;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

   &:hover {
      transform: translateY(-3px);
   }
}

.recipent-header-margin-left {
   margin-left: 10px;
}

.recipent-section {
   display: flex;
   flex-direction: column;
   gap: 10px;
   margin-bottom: 10px;
}

.recipent-back-button {
   background-color: white;
   padding: 0.5rem 1rem;
   border-radius: 9px;
   display: flex;
   align-items: center;
   font-size: 1.8rem;
   gap: 10px;
   justify-content: center;
   min-width: 40%;
   cursor: pointer;
   font-family: 'bebas';
   height: 64px;
}

.recipent-back-button:hover {
   opacity: 90%;
}

.product-2x1 {
   margin-left: 1rem;
   margin-right: 1rem;
   border-radius: 0.6rem;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.product-discount {
   background-color: lightgray;
   margin-left: 1rem;
   margin-right: 1rem;
   border-radius: 0.6rem;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.recipent-section-header {
   font-size: 1.6rem;
   font-weight: normal;
   margin: 0;
   margin-left: 1rem;
}

.register-box-center {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
}

.row-total {
   /*Display stuff*/
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: right;
   /*Box model stuff*/
   max-width: 100%;
   padding-top: 10px;
   color: white;
   background-color: #000;
   height: 50%;
}

.row-total h2 {
   font-size: 3rem;
   font-weight: normal;
}

@media screen and (max-width: 1200px) {
   .row-total h2 {
      font-size: 1.8rem;
      /* Further adjusted font size for very small screens */
   }
}

@media screen and (max-width: 950px) {
   .row-total h2 {
      font-size: 1.6rem;
      /* Further adjusted font size for very small screens */
   }
}

@media screen and (max-width: 680px) {
   .row-total h2 {
      font-size: 1.5rem;
      /* Further adjusted font size for very small screens */
   }
}

.row-total-money {
   /*Display stuff*/
   margin-left: 15px;
   margin-right: 1rem;
   display: flex;
   flex-direction: row;
   align-items: center;

   /*Box model stuff*/
   max-width: 100%;
   height: 100%;
   color: white;
}

.row-total-money h4 {
   color: rgb(255, 255, 255);
}

.row-subtotal {
   /*Display stuff*/
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: right;
   height: 50%;
   /*Box model stuff*/
   max-width: 100%;
   font-size: 25px;
   font-weight: normal;
   color: rgb(0, 0, 0);
   background-color: #D9D9D9;
   padding: 0.6rem;
}

.row-subtotal h4 {
   font-size: 2rem;
   font-weight: normal;
   margin: 0;
}

.receipt-button-row {
   width: 100%;
   display: flex;
   flex-direction: row;
   justify-content: space-evenly;
   padding: 0.5rem 0.7rem;
   font-size: 10px;
   padding-bottom: 1.2rem;

   @media screen and (max-width: 1200px) {
      gap: 0;
      justify-content: space-evenly;
   }
}

.charge {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 64px;
   width: 40%;
   border: none;
   border-radius: 9px;
   font-size: 2rem;
   font-weight: normal;
   color: rgb(0, 0, 0);
   cursor: pointer;
   gap: 1rem;
   box-sizing: border-box;
}

.receipt-cash-box-button {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: center;
   /*Box model stuff*/
   height: 64px;
   width: 64px;
   border: none;
   border-radius: 9px;
   background-color: white;
   /*Miscellaneous*/
   cursor: pointer;

   @media screen and (max-width: 1200px) {
      height: 48px;
      width: 48px;
   }
}

.receipt-cash-box-button:hover {
   opacity: 90%;
}

.receipt-cash-box-button:hover,
.charge--hover-shadow:hover {
   box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.45);
}

.receipt-cash-box-button:active,
.charge--hover-shadow:active {
   box-shadow: 0px 3px 3px 1px black;
}

.checkout-icon {
   color: #000000;
}

.empty-cart-icon {
   color: #F44336;
   width: 32px;
   height: 32px;

   @media screen and (max-width: 1200px) {
      width: 24px;
      height: 24px;
   }
}

.receipt-total-container {
   position: relative;
   width: 100%;
}

.empty-cart-button {
   align-items: center;
   background-color: whitesmoke;
   border: none;
   border-radius: 50px;
   box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
   color: gray;
   cursor: pointer;
   display: flex;
   font-size: 14pt;
   font-weight: bold;
   justify-content: space-evenly;
   position: absolute;
   padding: .8rem;
   z-index: 10;
   transform: translateX(-50%);
   bottom: 35%;
   left: 11%;
}

.empty-cart-button:hover {
   background-color: whitesmoke;
   box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
   color: black;

   .empty-cart-icon {
      color: red;
   }
}

@media screen and (max-width: 1400px) {
   .row-subtotal h4 {
      font-size: 1.8rem;
   }
}

@media screen and (max-width: 1200px) {
   .charge {
      font-size: 1.4rem;
      height: 48px;
      width: auto;
      padding-inline: 12px;
   }

   .row-subtotal h4 {
      font-size: 1.6rem;
   }
}

@media screen and (max-width: 1200px) {
   .img-logo {
      width: 110px;
   }
}

@media screen and (max-width: 901px) {
   .row-subtotal h4 {
      font-size: 1rem;
   }
}

@media screen and (max-width: 720px) {
   .register-options {
      height: 10%;
   }
}

@media screen and (max-width: 650px) {
   .product-2x1 {
      width: 86%;
   }
}

@media screen and (max-width: 610px) {
   .img-logo {
      width: 110px;
   }

   .header {
      justify-content: center;
      align-items: center;
   }

   .sales-date {
      font-size: 15px;
   }
}