.delivery-code-ui-modal {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.delivery-code-ui-modal h2{
    width: 100%;
    text-align: center;
    margin: 0;
    margin-bottom: 10px;
}

.delivery-code-ui-modal-header{
    font-size: 1.4rem;
    margin: 0;
}

.delivery-code-ui-modal input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 1rem;
    font-family: 'readex';
}

.delivery-code-ui-buttons-modal {
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
}

.delivery-code-ui-buttons-modal button {
    padding: 10px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-weight: bold;
    font-size: 1rem;
    transition: opacity 0.3s ease-in-out;
    width: 40%;
}

.delivery-code-ui-buttons-modal button:hover {
    opacity: 0.8;
}

.delivery-code-ui-buttons-modal button.cancel {
    background-color: #ccc;
}

.delivery-code-ui-buttons-modal button.confirm {
    background: -webkit-linear-gradient(-60deg, #ffd700, #fea 45%, #ffffff 60%, #fea 25%, #ffd700);
    color: black;
}

.delivery-code-confirm-modal {
    background: -webkit-linear-gradient(-45deg, #ffd700, #fea 25%, #ffffff 38%, #ffd700 63%, #fea 87%, #ffd700);
    color: black;
}

.delivery-code-cancel-modal {
    background-color: #9AA;
    color: whitesmoke;
}