@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.parent-myuser{
    display: flex;
    justify-content: space-between;
    height: 100%;
}

.logout-container{
    height: 100vh;
    width: 100%;
    background-color: #FFF4E2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.logout{
    width: 50%;
    height: 40%;
    /* background-color: #DEDADA; */
    border-radius: 9px;
    /* z-index: 10000; */
    /* margin: auto auto; */
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
    /* padding: 10px; */

    @media (max-width: 900px){
        flex-direction: column;
        align-items: center;
    }

    @media(max-width: 900px){
        height: 50%;
    }

    @media(max-width: 391px){
        width: 55%;
    }
}

.inner-logout-left{
    height: 60%;
    width: 40%;
    justify-content: left;
    /* border: 1px solid #000; */
    border-radius: 9px;
    padding: 20px;
    
}

.inner-logout-right{
    height: 70%;
    width: 70%;
    /* border: 1px solid #000; */
    border-radius: 9px;
    padding: 20px;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    
    @media (max-width: 710px){   
        width: 80%;
    }
    @media (max-width: 650px){   
        width: 90%;
    }
    @media (max-width: 540px){   
        width: 100%
    }
}

.logout-label{
    margin-top: 0px;
    text-align: center;
    font-size: 1.2em;
}
.field-logout{
    width: 100%;
    height: 50px;
    margin-top: 10px;
    margin-bottom: 25px;
    border-radius: 11px;
    background-color: white;
    border: none;
    padding: 10px;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .2);
    font-family: 'readex';

    @media(max-width: 1325px){
        width: 100%;
    }
}

.buttons-container{
    width: 100%;
    margin-top: 20px;
    display: flex;
    justify-content: left;
}


.buttons-container button{
    padding: 1rem;
    font-size: 1.3rem;
    border-radius: 8px;
    border: none;
    background-color: #f42c2c;
    color: white;
    font-family: 'bebas';
    box-shadow: 0px 2px 2px rgba(0, 0, 0, .2);

    @media(max-width: 400px){
        font-size: 1rem;
    }
}

.icon-out{
    margin-left: 10px;
    translate: 0px 3px;
}

#logout-btn{
    width: 60%;
    cursor: pointer;

    @media(max-width: 1325px){
        width: 100%;
    }
}

#logout-btn:hover{
    background-color: rgba(244, 44, 44, 0.68);
}

#pswd-btn{
    width: 50%;
    background-color: #ffc83d;
    cursor: pointer;
}

#pswd-btn:hover{
    background-color: hsla(43, 100%, 62%, 0.692);
}

.ext-photo{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* display: flex;
    justify-content: center;
    align-items: center; */
}

.user-photo{
    border: 1px solid #000;
    width: 160px;
    height: 160px;
    border-radius: 10px;
    background-color: #000;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 1320px){
        width: 140px;
        height: 140px;
    }

    @media (max-width: 1050px){
        width: 140px;
        height: 140px;
    }

    @media (max-width: 900px){
        width: 150px;
        height: 150px;
    }
}