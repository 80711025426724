.delivery-table-container {
    width: 100%;
    height: 100%;
    background-color: white;
    overflow: auto;
}

.deliveries-table {
    display: flex;
    flex-direction: column;
    font-family: Arial, sans-serif;
}

.header-table-row {
    display: flex;
    position: sticky; top: 0;
    background-color: #333;
    color: #fff;
}

.header-column {
    flex: 1;
    padding: 10px;
    font-family: 'bebas';
    font-size: 1.2rem;
    text-align: center;
    background-color: black;
    color: white;
}

.deliveries-table-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 8px;
}

.row {
    font-family: 'readex';
    border-radius: 10px;
    display: flex;
    align-items: center;
    background-color: #FFF4E2;
}

.delivery-row-div {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 10px;
    text-align: center;
    justify-content: space-evenly;
}

@media (max-width: 1200px) {
    .deliveries-table {
        font-size: 14px;
    }

    .header-column,
    .row > div {
        padding: 8px;
        font-size: 0.9rem;
    }
}

@media (max-width: 900px) {
    .hide-mobile {
        display: none;
    }
}

.delivery-button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 8px;
    width: fit-content;
    border-radius: 8px;
    cursor: pointer;
}

.delivery-button-icon-gold {
    background: -webkit-linear-gradient(-60deg, #ffd700, #fea 45%, #ffffff 60%, #fea 25%, #ffd700);
}

.delivery-button-icon-info {
    background: black;
}

.delivery-button-icon-green {
    background-color: #2D8205;
}

.delivery-center-text {
    display: flex;
    align-items: center;
}