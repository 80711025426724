.cut-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.cut-modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    margin-left: 4rem;
    width: 90%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    font-size: 1.4rem;
}

@media (max-width: 800px) {
    .cut-modal {
        
        padding: 20px;
        
        margin-left: 0px;
       
    }
    
 }

.cut-modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.cut-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.cut-modal-row-button {
    display: flex;
    
    justify-content: center;
}

.cut-modal-print-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: black;
    color: white;
    font-family: 'bebas';
    border: none;
    padding: 8px 16px;
    font-size: 1.4rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
        opacity: 90%;
    }
}



.cut-modal-body  {
    display: flex;
   
   flex-direction: column;
   justify-content: space-evenly;
   
   width: 100%;
   height: 80%;
}