.utility-payment-content .MuiBarElement-root:nth-of-type(1) {
    fill: green;
}

.utility-payment-content .MuiBarElement-root:nth-of-type(2) {
    fill: cornflowerblue;
}

.utility-payment-content .MuiBarElement-root:nth-of-type(3) {
    fill: coral;
}