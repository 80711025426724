.initialmoney-container {
    align-items: center;
    background-color: #FFF4E2;
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
}

.initialmoney-box {
    background-color: whitesmoke;
    border-radius: 11px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    padding: 0px;
    text-align: center;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.initialmoney-header-container {
    width: 100%;
    margin-bottom: 10px;
    padding: 20px 0px;
    background-color: #000;
    border-radius: 11px 11px 0px 0px;
}

.initialmoney-header-text {
    font-size: 1.3rem;
    color: white;
    font-weight: 300;
    padding: 0;
    margin: 0;
    width: 100%;
}

.initialmoney-description-text {
    color: black;
    font-size: 1rem;
    font-family: "Readex";
}

.initialmoney-input-container {
    width: 60%;
}

.initialmoney-gen{
    width: 100%;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    
}

.initialmoney-input-row {
    display: flex;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 4px;
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.initialmoney-input {
/*Display stuff*/
    flex: 1;
/*Box model stuff*/
    width: 50%;
    margin-left: 4px;
    padding: 8px;
    border: none;
    outline: none;
    background-color: transparent;
/*Typography stuff*/
    font-size: 1rem;
}

.initialmoney-button {
    margin-top: 40px;
    background-color: black;
    width: 47%;
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.initialmoney-button svg {
    margin-right: 6px;
}