.navbar {
  min-width: 70px;
  height: 100%;
  z-index: 9999;
}

.NavbarHeader {
  color: #fff;
  font-size: x-large;
}

.NavbarBottom {
  height: calc(100% - 20px);
}

.NavbarBottom {
  background-color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  height: 100%;
}

.NavbarBottomToggle {
  background-color: #111;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  position: fixed;
  bottom: 0;
  padding: 5px;
  width: 100%;
  height: 60px;
}

.NavImage {
  width: 50px;
}

.NavbarLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10%;
  position: relative;
}

.NavLink {
  align-items: center;
  border-radius: 0.5rem;
  color: #333;
  display: flex;
  margin: 0.5rem 0;
  padding: 0.75rem;
  position: relative;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.NavLink-selected {
  align-items: center;
  border-radius: 0.5rem;
  color: #333;
  background-color: #eee;
  display: flex;
  font-weight: bold;
  margin: 0.5rem 0;
  padding: 0.75rem;
  position: relative;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.NavLink:hover {
  background-color: #eee;
  color: #333;
}

.ToggleMenuButton {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  margin-top: 10px;
  background-color: #333;
  color: #fff;
}

@media screen and (max-width: 900px) {
  
  .navbar {
    min-width: 0;
    width: 0;
  }

  .NavbarLinks {
    position: sticky;
    top: 60px;
    left: 0;
    width: 100%;
    z-index: 9999;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
  }

  .ToggleMenuButton {
    display: block;
  }

  .NavbarBottom {
    display: none;
  }

  .NavLink {
    border-radius: 0;
    text-align: center;
  }

  .NavbarLinks a {
    color: whitesmoke;
    text-decoration: none;
  }

  .navbar .NavbarLinks {
    display: flex;
  }
}

@media screen and (min-width: 901px) {
  .ToggleMenuButton {
    display: none;
  }

  .NavbarBottomToggle {
    display: none;
  }

  .NavbarLinks a {
    text-decoration: none
  }
}

.NavIconContainer {
  position: relative;
}

.NavLabel {
  animation: fadeInOut 0.3s ease-in-out;
  background-color: #000000;
  border-radius: 0.4rem;
  font-size: 1.4rem;
  color: #ffffff;
  left: 54px;
  opacity: 0;
  padding: 10px;
  pointer-events: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: opacity 0.3s;
  z-index: 99999;
}

.NavLink:hover .NavLabel {
  opacity: 1;
}

@media screen and (max-width: 900px){
  .navbar .NavbarLinks{
      display: flex;
      flex-direction: row;
  }

  .NavbarLinks{
    width: 80%;
  }

  /* .NavBottom{
    display: none;
  } */

   .NavBottom:active {
    
    animation: fadeInOut 0.3s ease-in-out;
    background-color: #fff;
    color: #000;
    border-radius: 0.4rem;
    /* font-size: .2rem;  */
     /* bottom: 54px; */
    opacity: 1;
    padding: 10px;
    /* pointer-events: none; */
    /* position: absolute;  */
    z-index: 99999 !important;
    transition: opacity 0.3s; 
  
  }
}