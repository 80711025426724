.parent-customers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF4E2;
    height: 100%;
    width: 100%;
}

.parent-customer-tab {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.customers-container .table__row-mobile:hover {
    opacity: 0.6;
}

.bottom-row {
    background-color: black;
    display: flex;
    flex-direction: row;
    width: auto;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    align-items: center;
    justify-content: space-between;
}

.add-customer {
    width: 20%;
    font-size: 1.5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: white;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    padding: 0.5rem;
    gap: 1rem;
    height: 100%;
    min-height: 32px;
}

.customers-container .table__options {
    border-radius: 15px;
    background-color: white;
    height: 25px;
}

.options__button--edit {
    height: 27px;
    width: 27px;
}

.options__button--delete {
    height: 27px;
    width: 27px;
}

.options__button--hover-border:active {
    border-radius: 20px;
    box-shadow: 0px 2px 1px 1px;
}

.client-status {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20%;
    height: 100%;
    min-height: 40px;
    border-radius: 20px;
    color: antiquewhite;
    font-size: 25px;
}

.customer-scroll {
    width: 50%;
    height: 100%;
    border-radius: 9px;
    display: flex center;
}

.customer-scroll .Dropdown-control {
    width: 50%;
    height: 100%;
    border-radius: 9px;
}

.customer-scroll .Dropdown-menu {
    width: 70%;
    border-radius: 9px;
}

.customer-scroll .Dropdown-root {
    width: 70%;
    position: relative;
    z-index: 1;
    margin-top: 0;
}

customer-scroll .customer-scroll-control {
    position: relative;
}

.customer-scroll .Dropdown-menu {
    position: absolute;
    top: auto;
    bottom: 100%;
    max-height: 200px;
    overflow-y: auto;
}

.search__icon {
    margin: 0 1rem;
}

.parent-customers {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #FFF4E2;
    height: 100%;
    width: 100%;
}

.customers-container {
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    margin: 1rem;
    padding: 0 1rem 1rem 1rem;
    height: 85%;
    width: 100%;
    overflow: hidden;
}

.inventory-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 12px;
    border-top-left-radius: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.customers-box-border {
    background-color: black;
    margin-inline: 1rem;
    margin-top: 1rem;
    border-radius: 10px;
    padding-inline: 1rem;
    padding-bottom: 1rem;
    overflow-y: auto;
    width: calc(100% - 2rem);
    height: calc(100% - 2rem);
}

.customers-box-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
}

.customers-box {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.customers-header {
    display: flex;
    flex-direction: row;
    font-size: 1.4rem;
    background-color: #040404;
    color: white;
    justify-content: space-between;
    padding: 1rem;
    border-radius: 10px;
}

.header-item {
    flex: 1;
    text-align: center;
}

.customers-body {
    display: flex;
    background-color: white;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding-top: 10px;
}

.customers-row {
    flex-direction: row;
    font-family: 'readex';
    display: flex;
    align-items: center;
    background-color: #FFF4E2;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 12px;
    margin-inline: 10px;
}

.row-item {
    flex: 1;
    text-align: center;
}

.options {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.option-button,
.option-button-delete {
    background-color: #000;
    border: none;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
}

.add-customer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFD700;
    color: black;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    gap: 0.5rem;
}

.search-bar-customer {
    width: 30%;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 9px;
    padding: 0.5rem 1rem;
    flex: 1;
    margin-right: 1rem;
}

.search-bar-customer input[type="text"] {
    border: none;
    outline: none;
    flex: 1;
    font-size: 1rem;
    margin-left: 0.5rem;
    padding: 4px;
}

.customer-scroll {
    flex: 1;
}

.search__icon {
    margin-right: 0.5rem;
}

@media (max-width: 1400px) {
    
    .customers-container {
        height: 90%;
        margin: 0;
    }

    .bottom-row {
        padding: 1rem;
    }
}

@media (max-width: 900px) {

    .customers-container {
        height: 90%;
        margin: 0;
        margin-bottom: 2rem;
    }

    .inventory-container {
        overflow-y: auto;
    }

    .customers-box-border {
        width: 100%;
        padding: 0;
        margin: 0;
        overflow-y: auto;
        border-top-left-radius: 0;
    }

    .customers-header {
        padding-block: 8px;
        margin: 0;
    }

    .customers-row {
        padding: 0.5rem;
    }

    .header-item:nth-child(2),
    .header-item:nth-child(4),
    .row-item:nth-child(2),
    .row-item:nth-child(4) {
        display: none;
    }

    .bottom-row {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }

    .customer-scroll .Dropdown-control {
        width: 100%;
    }

    .add-customer,
    .search-bar-customer,
    .customer-scroll {
        width: 100%;
        margin: 0;
    }

    .bottom-row {
        position: relative;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;
        width: 100%;
    }
}