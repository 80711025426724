/* Back-container justifies and aligns the card container */
.back-container {
   align-items: center;
   display: flex;
   align-self: center;
   height: 84vh;
   justify-content: center;
   margin-inline: auto;
}

.parent-paydebt {
   background-color: #FFF4E2;
   display: flex;
   flex-direction: row;
   align-items: center;

   width: 100%;
   height: 100%;
}

.white-container {
   background-color: white;
   padding: 1rem 0 1rem 0;
   width: 100%;
   border-radius: 10px;
   margin-bottom: 1rem;
}

.debt-card {
   /*Display stuff*/
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   /*Box model stuff*/
   width: 500px;
   height: 650px;
   padding: 10px 10px;
   border-radius: 16px;
   background-color: #000;
   /*Miscellaneous*/
   box-sizing: border-box;
   padding-bottom: 20px;
}

.debt-header {
   color: rgb(255, 255, 255);
   font-size: 1.6rem;
   font-weight: 100;
   margin: 0px;
}

.debt-body {
   align-items: center;
   display: flex;
   flex-direction: column;
   height: 500px;
   justify-content: space-evenly;
   width: auto;
}

.pay-method {
   align-items: center;
   display: flex;
   flex-direction: column;
   width: 240px;
}

.pay-method-selected {
   border-radius: 2px 2px 0px 0px;
}

.pay-method__icons {
   display: flex;
   gap: 0px;
   justify-content: center;
   width: 200px;
}

.pay-method__button {
   background: transparent;
   border: none;
   width: 150px;
}

.pay-method__button--hover:hover {
   box-shadow: inset 0px 0.5px 6px 1px rgb(232, 219, 41);
}

.pay-method__button--click:active {
   box-shadow: inset 0px 0.5px 6px 1px black;
}

.pmselected {
   background-color: #FFF4E2;
   border-radius: 10px 10px 0px 0px;
}

.pay-method__instruction {
   background-color: #FFF4E2;
   border-radius: 10px;
   border-color: rgb(255, 218, 5);
   padding: 10px 10px;
   border-width: 5px;
   width: 400px;
}

.pay-method__instruction p {
   color: rgb(0, 0, 0);
   font-size: large;
   text-align: center;
}

.customer-rows {
   display: flex;
   flex-direction: column;
}

.customer-row {
   /*Display stuff*/
   display: flex;
   align-items: center;
   /*Box model stuff*/
   width: 100%;
   margin: 5px 0px;
   border-radius: 10px;
   background-color: whitesmoke;
}

.customer-row__icon {
   margin-right: 10px;
}

.gray__text {
   color: gray;
}

.customer-row__text {
   color: gray;
   font-size: 1.4rem;
}

.qty-rows {
   display: flex;
   flex-direction: column;
   gap: 10px;
   justify-content: space-between;
}

.subtotal-row {
   align-items: center;
   display: flex;
   justify-content: space-between;
   width: 250px;
}

.total-row {
   align-items: center;
   display: flex;
   justify-content: space-between;
}

.debt-text {
   font-size: 1.3rem;
   font-weight: normal;
   margin-right: 10px;
}

.debt-text--comission {
   color: black;
   font-size: 15px;
   font-weight: normal;
}

.input-box {
   display: flex;
   align-items: center;
   justify-content: space-evenly;
   width: 140px;
   height: 50px;
   border-radius: 10px;
   background-color: whitesmoke;
   overflow: hidden;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.subtotal {
   width: 50px;
   border: none;
   outline: none;
   background: transparent;
   font-size: 16px;
}

.total {
   /*Box model stuff*/
   width: 50px;
   border: none;
   outline: none;
   background: transparent;
   /*Typography stuff*/
   font-size: 16px;
}

.payDebt-buttons {
   display: flex;
   flex-direction: row;
   gap: 30px;
   justify-content: space-between;
}

.payDebt--cancel {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: space-around;
   /*Box model stuff*/
   width: 150px;
   padding: 8px 16px;
   border: none;
   border-radius: 6px;
   background-color: whitesmoke;
   /*Typography stuff*/
   font-size: 1.2rem;
   font-weight: bold;
   color: #F44336;
   /*Miscellaneous*/
   cursor: pointer;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.payDebt--exit {
   color: black;
}

.payDebt--save {
   /*Display stuff*/
   display: flex;
   align-items: center;
   justify-content: space-between;
   /*Box model stuff*/
   width: 150px;
   padding: 8px 16px;
   border: none;
   border-radius: 25px;
   background-color: whitesmoke;
   /*Typography stuff*/
   font-size: 1.2rem;
   font-weight: bold;
   color: #04c055;
   /*Miscellaneous*/
   cursor: pointer;
   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.payDebt--printer {
   padding-inline: 12px;
}

.hover-shadow:hover {
   box-shadow: 0px 1px 1px 0.5px;
   opacity: 1;
}

.click-shadow:active {
   box-shadow: 0px 2px 1px 1px;
}