.test {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFF4E2;
}

.th .table-header-exit {
    width: 15%;
    background-color: #000;
    color: #fff;
    flex-grow: 1;
    font-size: 1.4rem;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
}

.contenedor {
    width: 88%;
    height: 85%;
    background-color: antiquewhite;
    margin: auto;
    border-radius: 9px;
}

.opciones {
    background-color: #FFF4E2;
    /* background-color: aqua; */
    height: 7%;
    display: flex;
    align-items: flex-end;
}

.inventory-exits-clean-margin {
    margin-top: 0;
    padding-top: 0;
}

.pages-button {
    width: 18%;
    height: 85%;
    border: none;
    margin-right: 1%;
    border-radius: 8px 8px 0px 0px;
    background-color: #000;
    color: #FFF;
    font-size: 1.3em;
}

.cuadro {
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;

}

.lista-articulos {
    width: 97%;
    height: 95%;
    background-color: #FFF;
    border: 4px solid #000;
    border-radius: 8px;

}

.item-property {
    width: 20%;
    height: 10%;
    display: inline-block;
    text-align: center;
    background-color: #000;
    color: white;
    padding: 15px;

}

.inventory-search-bar-product {
    height: 100%;
    min-height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    background-color: whitesmoke;
    border-radius: 6px;
}

.inventory-row-bottom {
    background-color: black;
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 1rem;
    margin-top: 1rem;
    padding: 1rem;
    align-items: center;
}

@media (max-width: 1200px) {
    .inventory-row-bottom {
        display: flex;
        background-color: black;
        flex-direction: column;
        width: 100%;
        gap: 12px;
        margin: 0;
        margin-top: 1rem;
        align-items: flex-start;
    }

}



.buttons-row-container {
    height: 100%;

    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.buttons-row-container2 {
    height: 100%;
    width: 100%;
    display: flex;


    justify-content: space-between;
    gap: 1rem;
}

@media screen and (max-width:600px) {
    .buttons-row-container2 {

        flex-direction: column;
    }

}

/* Estilo para alternar color de fondo de los grupos */
.inventary-row-color {
    background-color: #e9ecef;
}

.bill-id-header {
    width: 100%;
    text-align: left;
    margin-top: 5px;
}

/* Estilos para cada fila de artículo dentro de un grupo */
.item-row {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    margin-top: 5px;
    margin-bottom: 5px;
    /* Espacio entre filas de artículos */
}


.item-row div {
    padding: 2px 10px;
}



.filtro {
    height: 10%;
    background-color: black;
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 0 0 10px 10px;
}

#search-div {
    border-radius: 9px;
    width: 35%;
    margin-right: 2%;
}

.inventary-exits-total {
    min-width: 25%;
    font-size: 1.8rem;
    color: white;
}

.inventary-row-color {
    background-color: silver !important;
}

.debt-row-color {
    background-color: #FFA8A8 !important;
}

.inventory-department-scroll .Dropdown-control {
    width: calc(100%)
}

.container-sum>p {
    display: flex;
    align-items: center;
    /* Centra el contenido verticalmente */
    justify-content: center;
    /* Centra el contenido horizontalmente */
    margin: 0;
    /* Remueve el margen por defecto de <p> */
}

.exits-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-collapse: separate;
    border-spacing: 0rem;
}

.table-box-top-hidden {
    padding-top: 0;
}

.movements-row {
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    border: 1px solid #ccc;
    background: rgb(255, 243, 226);

    white-space: nowrap;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'readex';
    font-size: 0.9rem;

    border-radius: 10px;
    width: 100%;
    padding: 10px;
    cursor: pointer;

    &:hover {
        transform: translateY(-2px);
    }
}

.row-verde {
    background: linear-gradient(90deg, rgba(255, 243, 226, 1) 0%, rgba(255, 243, 226, 1) 71%, rgba(153, 196, 5, 1) 100%);
}

.row-amarillo {
    background: linear-gradient(90deg, rgba(255, 243, 226, 1) 0%, rgba(255, 243, 226, 1) 71%, #D9AA08 100%);
}

.row-rojo {
    background: linear-gradient(90deg, rgba(255, 243, 226, 1) 0%, rgba(255, 243, 226, 1) 71%, rgba(248, 117, 117, 1) 100%);
}

.movments-item {
    flex: 1 1;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 6px;
}

.exits-tbody {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 1rem;
    padding-bottom: 1rem;
    padding-top: 0;
    background-color: white;

    @media (max-width: 800px) {
        padding: .4rem;
    }
}

.totals-item-large {
    flex: 3;
}

@media screen and (max-width: 1200px) {
    .inventary-exits-total {
        font-size: 1.4rem;
    }
}