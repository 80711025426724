.customer-container {
    align-items: center;
    display: flex;
    height: 84vh;
    justify-content: center;
}

.add-customer-card {
/*Display stuff*/
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-between;
/*Box model stuff*/
    width: 500px;
    max-width: 90%;
    height: 450px;
    border-radius: 16px;
    background-color: #399cd4;
}

.customer-input-row {
/*Display stuff*/
    display: flex;
    align-items: center;
/*Box model stuff*/
    gap: 6px;
    width: 84%;
    padding: 12px;
    padding-left: 10px;
    border-radius: 10px;
    background-color: white;
/*Miscellaneous*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.customer-input {
    width: 100%;
    height: 30px;
    background-color: transparent;
    border: none;
    font-size: 22px;
    margin-left: 10px;
    outline: none;
    font-family: "Readex";
}

.add-debt-row {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 0rem;
}

.add-debt__text {
    color: rgb(0, 0, 0);
    font-size: 1.6rem;
    font-weight: bold;
}

.add-debt__input-box {
/*Display stuff*/
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
/*Miscellaneous*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    width: 50%;
    height: 50px;
}

.add-debt__input {
/*Box model stuff*/
    width: 60px;
    border: none;
    background: transparent;
    outline:none;
/*Typography stuff*/
    font-size: 16px;
}

.add-customer__header {
    color: rgb(255, 255, 255);
    font-size: 1.6rem;
    font-weight:lighter;
    margin: 0px;
}

.drop-row .add-costumer-input {
    width: 100%;
    font-weight: normal;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 10px;
    font-family: "readex";
    padding: 4px;
    
    padding-left: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.add-costumer-origin-scroll {
    width: 100%;
    font-weight: normal;
    
    
}
.drop-row{
    width: 84%;
    
}


.add-costumer-origin-scroll .Dropdown-control {
    border-radius: 10px;
    border: none;
    text-align: left;
    font-weight: normal;
    font-family: "readex";
}
 
.add-costumer-origin-scroll .Dropdown-menu {
    z-index: 1110;
}

.add-customer-input {
/*Display stuff*/
    display: flex;
    align-items: center;
/*Box model stuff*/
    width: 84%;
    padding: 4px;
    padding-left: 10px;
    border-radius: 10px;
    background-color: white;
/*Miscellaneous*/
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Technique to hide input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
   -webkit-appearance: none;
   margin: 0;
}

input[type = number] {
    appearance: textfield;
   -moz-appearance: textfield;
}
/* End of technique */
/* Tutorial: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */

.add-customer-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.add-cancel-button {
/*Display stuff*/
    display: flex;
    align-items: center;
    justify-content: space-around;
/*Box model stuff*/
    width: 170px;
    padding: 8px 16px;
    border: none;
    border-radius: 25px;
    background-color: white;
/*Typography stuff*/
    font-size: 1.4rem;
    font-weight: bold;
    color: #F44336;
/*Miscellaneous*/
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.add-save-button {
/*Display stuff*/
    display: flex;
    align-items: center;
    justify-content: space-around;
/*Box model stuff*/
    width: 170px;
    padding: 8px 16px;
    border: none;
    border-radius: 25px;
    background-color: white;
/*Typography stuff*/
    font-size: 1.4rem;
    font-weight: bold;
    color: #04c055;
/*Miscellaneous*/
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.cancel-button svg,
.save-button svg {
    margin-right: 6px;
}

.hover-shadow:hover {
    box-shadow: 0px 1px 1px 0.5px;
    opacity: 1;
}

.click-shadow:active {
    box-shadow: 0px 2px 2px 2px;
}

.body-container{
    /*Display stuff*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
/*Box model stuff*/
    width: 100%;
    height: 91%;
    padding: 10px 10px;
    border-radius: 0px 0px 9px 9px;
    background-color: #f7ead5; 
}

.header-container{
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 9%;
    width: 100%;
    border-radius: 9px 9px 0px 0px;
}

.user-fields{
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    
}

.user-fields input{
    width: 80%;
}

.navbar{
    display: flex;
    
}

.parent-add-customer{
    display: flex;
    width: 100%;
    height: 100%;

}

.customer-container{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.generic-input{
    font-family: 'readex';
    color: #686767;
  }

@media screen and (max-width: 900px){

    .navbar{
        display: flex;
        position: absolute;
        bottom: 10px;
        max-height: 40px;
    }

}

