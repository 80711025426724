.bill-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    width: 80%;
    max-width: 800px;
    z-index: 1000;
}

.bill-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 999;
}

.bill-modal-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    position: relative;
    max-height: 70vh;
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.bill-modal-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    background-color: #000;
    color: #fff;
    padding: 10px 20px;
    border-radius: 8px 8px 0 0;
}

.modal-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: lighter;
}

.bill-close-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: white;
    color: black;
    font-family: 'bebas';
    border: none;
    padding: 8px 16px;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.bill-action-button {
    display: flex;
    align-items: center;
    gap: 4px;
    background-color: black;
    color: white;
    font-family: 'bebas';
    border: none;
    padding: 8px 16px;
    font-size: 1.125rem;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
        opacity: 90%;
    }
}

.bill-close-button:hover {
    opacity: 90%;
}

.bill-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 10px;
    align-items: center;
}

.bill-info {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.bill-modal-columns {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.bill-info-item {
    font-size: 1.2rem;
    margin-bottom: 8px;
}

.bill-products {
    border-top: 1px solid #ccc;
    padding-top: 10px;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.bill-products h3 {
    margin-top: 0;
    font-size: 1.25rem;
    color: #333;
}

.bill-products-table {
    width: 100%;
    border-collapse: collapse;
}

.bill-products-table th,
.bill-products-table td {
    padding: 8px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    text-align: center;
}

.bill-modal-button-row {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.bill-modal-margin-top {
    margin-top: 10px;
}

.bill-products-table td:first-child {
    text-align: left;
}

.bill-products-table th {
    background-color: #000;
    color: #fff;
    font-size: 1.2rem;
    font-weight: lighter;
}

.bill-products {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

.bill-products::-webkit-scrollbar {
    width: 8px;
}

.bill-products::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.bill-products::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
    border: 2px solid #f1f1f1;
}

.bill-products::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}

.bill-detail-modal-gray {
    color: #888;
    text-decoration: line-through;
}

.bill-modal-button-cancel {
    background-color: rgb(232, 71, 71);
}

.bill-modal-text-cancel {
    color: rgb(232, 71, 71);
}

@media (max-width: 768px) {
    .bill-modal {
        width: 90%;
        max-width: 90%;
    }

    .bill-info {
        flex-direction: column;
    }
}