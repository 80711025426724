.parent-inventary {
    display: flex;
    flex-direction: row;
    
    
    width: 100%;
    height: 100%;
    background-color: #FFF4E2;
 }

.inventary-father {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% - 70px);
    padding: 2rem;
}

@media (max-width: 800px) {

    .inventory-container {
        margin-bottom: 40px;
    }

    .inventory-category-scroll + .inventory-category-scroll {
        margin-top: 6px;
        margin-bottom: 6px;
    }
  
    .inventory-search-bar-product {
        width: 100%;
    }
  
    .inventory-category-scroll {
      width: 100%;
      font-size: 90%;
    }
  
    .grid-container {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr)); /* Adjust grid item width */
    }
}

@media screen and (max-width: 900px) {
    .inventary-father{
        width: 100%;;
        margin-bottom: 40px;
    }
    
}