.utilities-grid-item {
   display: flex;
   flex-direction: column;
   border-radius: 15px;
}

.utilities-grid-item-header {
   background-color: white;
   border-top-left-radius: 16px;
   border-top-right-radius: 16px;
   display: flex;
   font-family: 'readex';
   font-size: 1.4rem;
   font-weight: bold;
   justify-content: center;
   padding: 4px 0;
   height: 50px;
   width: 100%;

   @media (max-width: 550px) {
      font-size: 1rem;
   }
}

.utilities-grid-line {
   height: 6px;
   width: 100%;
}

.utilities-grid-item-container {
   background-color: white;
   border-bottom-left-radius: 16px;
   border-bottom-right-radius: 16px;
   display: flex;
   min-height: 220px;
   justify-content: center;
   align-items: center;
   height: 300px;

   @media (max-width: 550px) {
      height: auto;
   }
}