.customer-container {
  align-items: center;
  display: flex;
  height: 84vh;
  justify-content: center;
}

.edit-customer-card {
/*Display stuff*/
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: space-between;
/*Positioning stuff*/
  position: relative;
/*Box model stuff*/
  width: 500px;
  max-width: 94%;
  height: 500px;
  padding: 10px 10px;
  border-radius: 16px;
  background-color: #399cd4;
/*Miscellaneous*/
  box-sizing: border-box;
}

.drop-row-EC {
  font-weight: normal;
  display: flex;
  flex-direction: row;
  font-family: "readex";
  gap: 10px;
  justify-content: center;
}

.edit-customer__header {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  font-weight: bolder;
  margin: 0px;
}

.edit-customer__input {
/*Display stuff*/
  display: flex;
  align-items: center;
  justify-content: space-evenly;
/*Box model stuff*/
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  background-color: white;
/*Miscellaneous*/
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.edit_input {
/*Positioning stuff*/
   position: relative;
   left: 10px;
/*Box model stuff*/
   padding-left: 10px;
   border: none;
   outline: none;
   background: none;
/*Typography stuff*/
   font-size: 1.1rem;
}

/* Only this icon needs repositioning */
.edit-customer__icon--p {
   width: 31px;
   left: 5px;
   position: relative;
}

.debt-row {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.debt-row--text {
  color: white;
  font-weight: bolder;
  font-size: 1.3rem;
}

.edit-customer__input input[type="number"] {
/*Box model stuff*/
  width: 150px;
  /* Trick to hide input arrows */
  appearance: textfield;
  -moz-appearance: textfield;
  -webkit-appearance: none;
  /* Tutorial: https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp */
}

.debt__button {
/*Display stuff*/
  display: flex;
  align-items: center;
  justify-content: space-evenly;
/*Box model stuff*/
  width: 200px;
  height: 45px;
  padding: 0px 15px;
  border: none;
  border-radius: 20px;
  background-color: white;
/*Typography stuff*/
  font-size: 1.1rem;
  font-weight: bold;
  color: #686767;
/*Miscellaneous*/
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.edit-customer-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  /* gap: 30px; */
}

.button--cancel {
/*Display stuff*/
  display: flex;
  align-items: center;
  justify-content: space-between;
/*Box model stuff*/
  width: 150px;
  padding: 8px 16px;
  border: none;
  border-radius: 25px;
  background-color: white;
/*Typography stuff*/
  font-size: 1.2rem;
  font-weight: bold;
  color: #F44336;
/*Miscellaneous*/
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.button--save {
/*Display stuff*/
  display: flex;
  align-items: center;
  justify-content: space-between;
/*Box model stuff*/
  width: 150px;
  padding: 8px 16px;
  border: none;
  border-radius: 25px;
  background-color: white;
/*Typography stuff*/
  font-size: 1.2rem;
  font-weight: bold;
  color: #04c055;
/*Miscellaneous*/
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.button--delete {
  /* Display stuff */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Box model stuff */
  width: 150px;
  padding: 8px 16px;
  border: none;
  border-radius: 25px;
  /* Typography stuff */
  font-size: 1.2rem;
  font-weight: bold;
  color: #F44336; /* Color del texto en el botón de eliminar */
  /* Miscellaneous */
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.hover-shadow:hover {
   box-shadow: 0px 1px 1px 0.5px;
   opacity: 1;
}

.click-shadow:active {
   box-shadow: 0px 2px 1px 1px;
}

.debt__button--disabled {
  background-color: rgb(222, 222, 222);
}

.charge-debt{
  width: 45%;
  height: 13%;
  border:none;
  border-radius: 9px;
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 0px 25px;
}

.generic-input{
  font-family: 'readex';
  color: #686767;
}

 .edit-customer-buttons{
  font-family: 'bebas';
  font-size: 1.5rem;
}

.dropdown-customer-input {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  padding: 4px;
  gap: 10px;
  padding-left: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-size: 1rem;
  font-family: "readex";
}

.add-customer-scroll {
  width: 100%;
}

.add-customer-scroll .Dropdown-control{
  border-color: transparent;
  cursor: pointer;
  padding-right: 30px;
  width: auto;
}

.add-customer-scroll .Dropdown-menu{
  z-index: 99999;
  border-color: transparent;
}