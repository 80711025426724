.utility-payment-content .css-c1eq84-MuiPieArcLabel-root {
    fill: white;
    font-size: 1.25rem;
}

.utility-stock-father {
    position: relative;
    padding-bottom: 34px;
}

.utility-stock-total {
    position: absolute;
    bottom: 0;
    left: 8px;
    height: 34px;
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    width: auto;
    margin: 0;
}