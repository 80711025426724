.login-container {
    align-items: center;
    background-color: #FFF4E2;
    display: flex;
    height: 100vh;
    justify-content: center;
}

.login-box {
    background-color: #111;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 400px;
    padding-bottom: 40px;
    padding-top: 20px;
    text-align: center;
    width: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.6);
}

.login-header-container {
    width: 100%;
    margin-bottom: 16px;
}

.login-header-text {
    font-size: 1.8rem;
    font-weight: normal;
    color: white;
    padding: 0;
    margin: 0;
}

.login-logo-container {
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: white;
    margin: 0 auto 50px;
    width: 100%;
}

.login-input-container {
    width: 70%;
    margin-bottom: 10px;
}

.login-label {
    font-size: 1.4rem;
    color: white;
    display: block;
    margin-bottom: 2px;
    text-align: left;
}

.login-input {
    font-family: 'readex';
    border-radius: 6px;
    border: 1px solid #ccc;
    padding: 10px;
    font-size: 1rem;
    width: 100%;
}

.login-button {
    height: 42px;
    margin-top: 32px;
    padding: 0 18px;
}

.login-button:hover {
    background-color: #f0f0f0;
}

@media (max-width: 768px) {
    .login-box {
        width: 96%;
    }
}